import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import i18n from 'i18n-js';
import clsx from 'clsx';
import {
    createStyles,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Tooltip,
    Menu,
    MenuItem,
    Radio,
    Typography,
} from '@material-ui/core';

import userManager from 'managers/userManager';
import { OrderStatus, Positions, Roles } from 'constants/enums';
import { NavRestaurantSwitcher } from './components/NavRestaurantSwitcher';
import commonStyles from 'config/commonStyles';
import { default as userStore } from 'store/userStore';
import { default as uiStore } from 'store/uiStore';
import images from 'assets/images';
import AdminNavbar from './components/AdminNavbar';
import RestaurantOwnerNavbar from './components/RestaurantOwnerNavbar';
import RestaurantEmployeeNavbar from './components/RestaurantEmployeeNavbar';
import WorkingDayWarning from './components/WorkingDayWarning';
import OverdudedOrdersPanel from './components/OverdudedOrdersPanel';
import { PopupConfirmation, Text } from '../shared';
import { NavLanguageSwitcher } from './components/NavLanguageSwitcher';
import { truncateString, getPositionName } from '../../helpers/helperFunctions';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useAppSelector } from 'index';

const drawerWidth = 256;

const useStyles = makeStyles((theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            '& .MuiTypography-root.MuiListItemText-primary,': {
                color: theme.palette.primary.dark,
                opacity: 1,
                textTransform: 'none',
                fontSize: 14,
                fontWeight: 600,
            },
            '& .MuiPaper-root.MuiDrawer-paper > div': {
                marginTop: 21,
            },
            '& .MuiPaper-root.MuiDrawer-paper > ul': {
                marginBottom: 21,
            },
            '& .MuiDrawer-paper': {
                justifyContent: 'space-between',
            },
        },
        drawerOpen: {
            overflowX: 'hidden',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: 69,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            height: 40,
            padding: theme.spacing(0, 1),
        },
        logoContainer: {
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        logoContainerOpened: {
            height: 44,
            width: 108,
            backgroundImage: `url(${images.logo})`,
        },
        logoContainerCollapsed: {
            height: 42,
            width: 54,
            paddingLeft: 25,
            backgroundImage: `url(${images.icons.logoNoText})`,
        },
        navbarList: {
            width: 'auto',
            transition: 'padding 0.5s',
            '& .MuiListItem-root .MuiListItemIcon-root': {
                marginRight: 0,
                minWidth: 32,
                justifyContent: 'center',
            },
            '& .MuiListItem-root': {
                height: 40,
                margin: '5px 0',
                cursor: 'pointer',
            },
            '& .MuiListItemText-root': {
                margin: '0 0 0 25px',
            },
            '& .MuiListItem-gutters': {
                transition: 'padding 0.5s',
            },
        },
        navbarListOpened: {
            padding: '0 8px',
        },
        navbarListCollapsed: {
            padding: '0 15px',
            '& .MuiListItem-gutters': {
                paddingLeft: 0,
                paddingRight: 0,
            },
            '& .MuiListItem-root .MuiListItemIcon-root': {
                width: '100%',
                maxWidth: 34,
            },
        },
        collapsedIconContainer: {
            ...commonStyles.flexRowCenter,
            cursor: 'pointer',
            height: 34,
            width: 34,
            boxShadow: '0px 0px 7px rgba(140, 121, 133, 0.3)',
            borderRadius: 4,
        },
        openedIcon: {
            transition: 'transform 0.5s',
        },
        collapsedIcon: {
            transition: 'transform 0.5s',
            transform: 'rotate(180deg)',
        },
        navbarLogAction: {
            padding: '0 0 0 16px',
            '& .MuiListItemText-root': {
                margin: 0,
            },
        },
        navbarLogActionCollapsed: {
            padding: '0 !important',
            '& .MuiListItemText-root': {
                margin: 0,
            },
        },
        profileIcon: {
            height: 32,
            width: 32,
            borderRadius: 16,
        },
        workingDayContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.error.light,
            width: '100%',
            padding: '0 8px',
            height: '40px',
            borderRadius: '4px',
        },
        workingDayText: {
            color: theme.palette.common.white,
            fontSize: '12px',
            lineHeight: '21px',
        },
        logoVersionContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '140%',
        },
        versionText: {
            fontSize: '9px',
            fontWeight: 500,
            color: theme.palette.primary.dark,
        },
        imageContainer: {
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: 24,
            width: 24,
        },
        dropdown: {
            '& .MuiPopover-paper ': {
                top: '190px !important',
                left: '0px !important',
                width: 260,
            },
            '& ul>li': {
                display: 'block',
                padding: 0,
                borderBottom: 'none',
            },
            '& ul>li:hover': {
                backgroundColor: 'transparent',
            },
            '& .MuiMenuItem-root': {
                minHeight: '48px !important',
                margin: 0,
            },
            '& .MuiFormControl-root': {
                padding: '10px 0 0 10px',
                backgroundColor: 'none',
            },
            /* '& .MuiRadio-root': {
                display: 'block',
            }, */
            '& li .MuiRadio-root .MuiIconButton-label': {
                justifyContent: 'flex-start',
            },
        },
        dropdownMain: {
            padding: 10,
            display: 'flex',
            justifyContent: 'space-between',
        },
        selected: {
            backgroundColor: theme.palette.background.default,
        },
    })
);

export default function NavigationBar() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useAppSelector((state) => state.user);
    const currentRestaurant = user.restaurants?.find((x) => x.id === user.currentRestaurantId);
    const restaurantInformation = useAppSelector(
        (state) => state.restaurantDetails.restaurantInformation
    );
    const orders = useAppSelector((state) => state.orders.orders);
    const location = useAppSelector((state) => state.router.location.pathname);
    const isOpen = useAppSelector((state) => state.ui.navBarOpen);
    const categories = useAppSelector((state) => state.category.categoryDetails?.categories);
    const [, setIsOpen] = useState(true);
    const classes = useStyles();
    const [, setPath] = useState('/');
    const [homePage, setHomepage] = useState('/');
    const [workingDayWarning, setWorkingDayWarning] = useState<string>('');
    const [showCollapseTooltip, setShowCollapseTooltip] = useState(false);
    const [showLogoutConfirmation, setLogoutConfirmation] = useState(false);
    const profileImgRef = useRef(null);

    const weekDays: { [propKey: string]: string } = {
        '0': 'sunday',
        '1': 'monday',
        '2': 'tuesday',
        '3': 'wednesday',
        '4': 'thursday',
        '5': 'friday',
        '6': 'saturday',
    };

    async function logout() {
        localStorage.clear();
        await userManager.signoutRedirect();
        await userManager.removeUser();
    }

    function login() {
        // pass the current path to redirect to the correct page after successfull login
        userManager.signinRedirect({
            data: { path: location },
        });
    }

    function renderMainNavbar() {
        switch (user.role) {
            case Roles.OWNER:
                if (currentRestaurant?.currentPosition === Positions.COOK) {
                    return <RestaurantEmployeeNavbar isOpen={isOpen} />;
                } else if (currentRestaurant?.currentPosition === Positions.MANAGER) {
                    return <RestaurantOwnerNavbar isOpen={isOpen} />;
                } else if (
                    currentRestaurant?.currentPosition === Positions.WAITER ||
                    currentRestaurant?.currentPosition === Positions.BARMAN
                ) {
                    return <RestaurantEmployeeNavbar isOpen={isOpen} />;
                }
                break;
            case Roles.EMPLOYEE:
                return <RestaurantEmployeeNavbar isOpen={isOpen} />;
            case Roles.ADMIN:
                return <AdminNavbar isOpen={isOpen} />;
            default:
                return <></>;
        }
    }

    useEffect(() => {
        if (location !== '/silentRenew') {
            setPath(location);
        }
        if (location === '/') {
            let route = '/';
            setTimeout(() => {
                if (user.role && (user.role === Roles.OWNER || user.role === Roles.EMPLOYEE)) {
                    route = '/orders';
                } else if (user.role && user.role === Roles.ADMIN) {
                    route = '/restaurants';
                }

                setHomepage(route);
                setPath(route);
                route !== location && history.push(route);
            }, 500);
        }

        if (!!user.role) {
            dispatch(userStore.actionCreators.getUserProfile());
        }
    }, [location, !!user.role, user.currentRestaurantId]); // eslint-disable-line

    useEffect(() => {
        if (!currentRestaurant?.currentCategory) {
            dispatch(
                userStore.actionCreators.setCategory(
                    categories?.find((x) => x.position === currentRestaurant?.currentPosition)?.id
                )
            );
        }
    }, [categories?.length, currentRestaurant?.currentPosition]);

    function showWorkingDayWarning() {
        // @ts-ignore
        if (!restaurantInformation || !restaurantInformation.openHours) {
            return false;
        }
        const dayNumber = new Date().getDay();
        const dayText = weekDays[dayNumber.toString()];
        // @ts-ignore
        const workingHour = restaurantInformation.openHours[dayText];
        if (workingHour.isClosed || !workingHour.workingHours[0]) {
            return false;
        }
        const currentDate = new Date();
        const workingHourHours = workingHour.workingHours[0]?.to?.toString().split('.')[0];
        let workingHourMinutes = workingHour.workingHours[0]?.to?.toString().split('.')[1];

        workingHourMinutes = !workingHourMinutes ? 0 : workingHourMinutes;

        const closingTime = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            workingHourHours,
            workingHourMinutes
        );

        const difference = closingTime.getTime() - currentDate.getTime(); // This will give difference in milliseconds
        const differenceInMinutes = Math.round(difference / 60000);
        if (differenceInMinutes > 0 && differenceInMinutes < 31) {
            const warningStatusList = [
                OrderStatus.New,
                OrderStatus.InProgress,
                OrderStatus.Ready,
                OrderStatus.Unpaid,
            ];
            if (orders.filter((eachOrder) => warningStatusList.includes(eachOrder.status)).length) {
                return true;
            }
        }
        return false;
    }

    function getWorkingHoursStr() {
        const dayNumber = new Date().getDay();
        const dayText = i18n.t(`common.weekDayKeys.${dayNumber}`);
        // @ts-ignore
        const workingHour = restaurantInformation.openHours[dayText.toLowerCase()];
        let startHour = workingHour.workingHours[0].from.toString().split('.')[0];
        let startMinute = workingHour.workingHours[0].from.toString().split('.')[1] || '0';

        let endHour = workingHour.workingHours[0].to.toString().split('.')[0];
        let endMinute = workingHour.workingHours[0].to.toString().split('.')[1] || '0';

        startHour = startHour.length === 1 ? '0' + startHour : startHour;
        startMinute = startMinute.length === 1 ? '0' + startMinute : startMinute;
        endHour = endHour.length === 1 ? '0' + endHour : endHour;
        endMinute = endMinute.length === 1 ? '0' + endMinute : endMinute;

        return `${startHour}:${startMinute}-${endHour}:${endMinute}`;
    }

    function getRoleIcon(position?: Positions) {
        let positionToCheck = position || currentRestaurant?.currentPosition;
        if (positionToCheck) {
            switch (positionToCheck) {
                case Positions.COOK:
                    return images.icons.chef;
                case Positions.BARMAN:
                    return images.icons.barmen;
                case Positions.WAITER:
                    return images.icons.waiter;
                case Positions.MANAGER:
                    return images.icons.manager;
                default:
                    return images.icons.barmen;
            }
        } else {
            switch (user.role) {
                case Roles.OWNER:
                    return images.icons.manager;
                case Roles.ADMIN:
                    return images.icons.manager;
                default:
                    return images.icons.barmen;
            }
        }
    }

    function handlePositionAndCategory(position: string, category?: string) {
        if (currentRestaurant?.currentPosition !== position) {
            dispatch(userStore.actionCreators.setCurrentPosition(position));
        }
        if (category) {
            if (currentRestaurant?.currentCategory !== category) {
                dispatch(userStore.actionCreators.setCategory(category));
            }
        } else {
            dispatch(
                userStore.actionCreators.setCategory(
                    categories?.find((x) => x.position === position)?.id
                )
            );
        }
    }

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                }),
            }}
        >
            <div>
                {isOpen ? (
                    <div
                        className={classes.toolbar}
                        style={{
                            justifyContent: isOpen ? 'space-between' : 'center',
                            paddingRight: isOpen ? 15 : 0,
                            minHeight: 42,
                            marginBottom: isOpen ? 20 : 0,
                        }}
                    >
                        <Link
                            to={homePage}
                            className="btn btn-link"
                            style={{ textDecorationLine: 'none' }}
                        >
                            <div className={classes.logoVersionContainer}>
                                <div
                                    className={clsx(classes.logoContainer, {
                                        [classes.logoContainerCollapsed]: !isOpen,
                                        [classes.logoContainerOpened]: isOpen,
                                    })}
                                />
                                <span className={classes.versionText}>
                                    version {process.env.REACT_APP_VERSION}
                                </span>
                            </div>
                        </Link>
                    </div>
                ) : (
                    <>
                        <Link to={homePage} className="btn btn-link">
                            <div
                                className={clsx(classes.logoContainer, {
                                    [classes.logoContainerCollapsed]: !isOpen,
                                    [classes.logoContainerOpened]: isOpen,
                                })}
                            />
                        </Link>
                    </>
                )}
                {!!user.role && (
                    <>
                        {(user.role === Roles.OWNER || user.role === Roles.EMPLOYEE) && (
                            <NavRestaurantSwitcher
                                isOpen={isOpen}
                                open={() => dispatch(uiStore.actionCreators.switchNavBar())}
                            />
                        )}
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <>
                                    <List
                                        className={clsx(classes.navbarList, {
                                            [classes.navbarListOpened]: isOpen,
                                            [classes.navbarListCollapsed]: !isOpen,
                                        })}
                                        style={{ marginBottom: 7 }}
                                    >
                                        <ListItem
                                            key={'profile'}
                                            style={{ paddingLeft: isOpen ? 16 : 3 }}
                                            {...bindTrigger(popupState)}
                                        >
                                            <Tooltip
                                                title={`${user.profile.firstName} ${user.profile.lastName}`}
                                                placement="bottom-start"
                                                disableHoverListener={isOpen}
                                            >
                                                <ListItemIcon>
                                                    <img
                                                        ref={profileImgRef}
                                                        className={classes.profileIcon}
                                                        src={
                                                            user.profile.photo ??
                                                            images.icons.userAvatar
                                                        }
                                                        alt="profile-icon"
                                                        onError={() => {
                                                            // @ts-ignore
                                                            profileImgRef.current.src =
                                                                images.icons.userAvatar;
                                                        }}
                                                    />
                                                </ListItemIcon>
                                            </Tooltip>
                                            <Tooltip
                                                title={`${user.profile.firstName} ${user.profile.lastName}`}
                                                disableHoverListener={
                                                    `${user.profile.firstName} ${user.profile.lastName}`
                                                        .length < 16
                                                }
                                            >
                                                <ListItemText
                                                    primary={truncateString(
                                                        `${user.profile.firstName} ${user.profile.lastName}`,
                                                        16
                                                    )}
                                                    secondary={
                                                        user.role === Roles.ADMIN
                                                            ? 'Admin'
                                                            : currentRestaurant?.currentPosition
                                                    }
                                                />
                                            </Tooltip>
                                            <img src={getRoleIcon()} alt={'role-icon'} />
                                        </ListItem>
                                    </List>
                                    <Menu
                                        {...bindMenu(popupState)}
                                        className={classes.dropdown}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        {currentRestaurant?.positions?.map((pos, i) => (
                                            <MenuItem key={`pos-${i}-positions`}>
                                                <div
                                                    className={clsx(classes.dropdownMain, {
                                                        [classes.selected]:
                                                            currentRestaurant.currentPosition ===
                                                            pos,
                                                    })}
                                                    onClick={() => {
                                                        handlePositionAndCategory(pos);
                                                        popupState.close();
                                                    }}
                                                >
                                                    <Typography variant="inherit">
                                                        {getPositionName(pos)}
                                                    </Typography>
                                                    <ListItemIcon>
                                                        <img
                                                            src={getRoleIcon(pos)}
                                                            alt={'role-icon'}
                                                        />
                                                    </ListItemIcon>
                                                </div>
                                                <div>
                                                    {categories?.map((category) =>
                                                        category.position === pos ? (
                                                            <div
                                                                key={`${pos}-${category.id}`}
                                                                onClick={() => {
                                                                    handlePositionAndCategory(
                                                                        pos,
                                                                        category.id
                                                                    );
                                                                    popupState.close();
                                                                }}
                                                            >
                                                                <Radio
                                                                    color="primary"
                                                                    checked={
                                                                        currentRestaurant.currentCategory ===
                                                                        category.id
                                                                    }
                                                                    value={category.id}
                                                                    name="radio-button-demo"
                                                                    inputProps={{
                                                                        'aria-label': 'D',
                                                                    }}
                                                                />
                                                                <span>{category.name}</span>
                                                            </div>
                                                        ) : null
                                                    )}
                                                </div>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </PopupState>
                    </>
                )}

                <Divider />
                {renderMainNavbar()}
            </div>
            <div>
                <List
                    className={clsx(classes.navbarList, {
                        [classes.navbarListOpened]: isOpen,
                        [classes.navbarListCollapsed]: !isOpen,
                    })}
                >
                    {showWorkingDayWarning() && (
                        <ListItem
                            onClick={() => {
                                setWorkingDayWarning(getWorkingHoursStr());
                            }}
                        >
                            <div className={classes.workingDayContainer}>
                                {isOpen && (
                                    <Text
                                        withoutMore
                                        maxLength={15}
                                        className={classes.workingDayText}
                                        text={i18n.t('navigationTabs.workingDayWarning')}
                                    />
                                )}
                                <img src={images.icons.whiteWarning} alt="white-warning" />
                            </div>
                        </ListItem>
                    )}
                    <WorkingDayWarning
                        workingHours={workingDayWarning}
                        open={workingDayWarning.length > 0}
                        onClose={() => setWorkingDayWarning('')}
                    />
                    {user.role === Roles.OWNER && (
                        <OverdudedOrdersPanel navigationBarOpen={isOpen} />
                    )}
                    <NavLanguageSwitcher isOpen={isOpen} open={() => setIsOpen(true)} />
                    <ListItem onClick={() => dispatch(uiStore.actionCreators.switchNavBar())}>
                        <Tooltip
                            title={i18n.t('navigationTabs.maximazeMenu')}
                            placement="bottom-start"
                            open={showCollapseTooltip}
                            disableHoverListener={isOpen}
                        >
                            <ListItemIcon
                                className={classes.collapsedIconContainer}
                                style={{ color: 'red', marginLeft: isOpen ? 0 : 2 }}
                                onMouseEnter={() => !isOpen && setShowCollapseTooltip(true)}
                                onMouseLeave={() => setShowCollapseTooltip(false)}
                            >
                                <div
                                    className={clsx(classes.imageContainer, {
                                        [classes.openedIcon]: isOpen,
                                        [classes.collapsedIcon]: !isOpen,
                                    })}
                                    style={{ backgroundImage: `url(${images.icons.collapseIcon})` }}
                                />
                            </ListItemIcon>
                        </Tooltip>

                        <ListItemText primary={i18n.t('navigationTabs.minimazeMenu')} />
                    </ListItem>
                </List>
                <Divider style={{ margin: '40px 0 15px 0' }} />
                <List
                    className={clsx(classes.navbarList, {
                        [classes.navbarListOpened]: isOpen,
                        [classes.navbarListCollapsed]: !isOpen,
                    })}
                >
                    {!!user.role ? (
                        <ListItem
                            onClick={(event: any) => setLogoutConfirmation(true)}
                            key={'logout'}
                        >
                            <Tooltip
                                title={i18n.t('navigationTabs.logout')}
                                placement="bottom-start"
                                disableHoverListener={isOpen}
                            >
                                <ListItemIcon>
                                    <div
                                        className={classes.imageContainer}
                                        style={{ backgroundImage: `url(${images.icons.logout})` }}
                                    />
                                </ListItemIcon>
                            </Tooltip>

                            <ListItemText primary={i18n.t('navigationTabs.logout')} />
                        </ListItem>
                    ) : (
                        <ListItem
                            className={
                                isOpen ? classes.navbarLogAction : classes.navbarLogActionCollapsed
                            }
                            component={Link}
                            to={'#'}
                            onClick={() => login()}
                            key={'users'}
                        >
                            <ListItemText primary={i18n.t('navigationTabs.login')} />
                        </ListItem>
                    )}
                </List>
                <PopupConfirmation
                    open={showLogoutConfirmation}
                    close={() => setLogoutConfirmation(false)}
                    title={i18n.t('navigationTabs.logout')}
                    description={i18n.t('confirmation.sureToLogout')}
                    activeBtn={i18n.t('navigationTabs.logout')}
                    action={() => logout()}
                />
            </div>
        </Drawer>
    );
}
