import {
    RestaurantFilterModel,
    RestaurantTypesManagementModel,
    RestaurantPaymentDetailsModel,
    TaxRequestModel,
} from 'api/models';
import { RestaurantDetails, QRData, QRCodesData } from 'store/restaurantDetailsStore/reducer';
import { RestaurantCreateModel } from 'store/restaurantsStore/reducer';
import agent, { prepareHeaders } from 'api/agent';

export const Restaurants = {
    Create: (model: RestaurantCreateModel) =>
        agent.requests.put('/Restaurant/create', model, prepareHeaders()),
    Update: (id: string, model: RestaurantCreateModel) =>
        agent.requests.post(`Restaurant/${id}`, model),
    GetList: (
        filterModel: RestaurantFilterModel | null = null,
        page?: number,
        limit?: number,
        asc?: boolean,
        orderBy?: string
    ) =>
        agent.requests.get(
            '/Restaurant/list',
            filterModel != null
                ? {
                      params: {
                          Name: filterModel!.name,
                          AddressLine: filterModel!.addressLine,
                          ZIP: filterModel!.zip,
                          City: filterModel!.city,
                          page: page,
                          limit: limit,
                          asc: asc,
                          orderBy: orderBy,
                      },
                  }
                : {
                      params: {
                          page: page,
                          limit: limit,
                          asc: asc,
                          orderBy: orderBy,
                      },
                  }
        ),
    GetDetails: (id: string) => agent.requests.get(`Restaurant/${id}`),
    GetRestaurantInformation: () => agent.requests.get('Restaurant/getdetails'),
    UpdateRestaurantInformation: (model: RestaurantDetails) =>
        agent.requests.post('Restaurant/updatedetails', model),
    GetCuisineTypes: () => agent.requests.get('Restaurant/types'),
    CreateCuisineType: (model: { name: string }) =>
        agent.requests.put('Restaurant/types', model, prepareHeaders()),
    ManageCuisineTypes: (model: RestaurantTypesManagementModel) =>
        agent.requests.post('Restaurant/types', model),
    GetQrs: (params = {}) => agent.requests.get('Restaurant/qrcodes', { params }),
    CreateQrCode: (qrCode: QRData, params = {}) =>
        agent.requests.put('Restaurant/qrcodes', qrCode, { ...prepareHeaders(), params }),
    UpdateQrCode: (qrCode: QRData) => agent.requests.post('Restaurant/qrcodes', qrCode),
    DeleteQrCode: (qrIds: string[]) => agent.requests.delete('Restaurant/qrcodes', qrIds),
    GetFinance: (dateFrom: any, dateTo: any, restaurantId = '') =>
        agent.requests.get('Restaurant/finance', {
            params: {
                dateFrom,
                dateTo,
                restaurantId,
            },
        }),

    DisableRestaurant: (id: string) => agent.requests.post(`Restaurant/disable/${id}`),
    UpdatePaymentDetails: (model: RestaurantPaymentDetailsModel) =>
        agent.requests.post('Restaurant/paymentdetails', model),
    UpdateQrCodesNames: (model: QRCodesData) =>
        agent.requests.post('Restaurant/qrcodes/name', model),
    GetTaxes: () => agent.requests.get('Restaurant/taxes'),
    UpdateTaxes: (id: string, model: TaxRequestModel) =>
        agent.requests.post(`Restaurant/taxes/${id}`, model),
};
