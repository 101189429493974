import agent, { prepareHeaders } from 'api/agent';
import { CreateCategoryModel } from 'store/categoryStore/reducer';

export const Category = {
    GetCategoryDetails: (asc: boolean, orderBy: string) =>
        agent.requests.get(`/Category?asc=${asc}&orderBy=${orderBy}`),
    CreateCategory: (model: CreateCategoryModel) =>
        agent.requests.put('/Category/Create', model, prepareHeaders()),
    EditCategory: (model: CreateCategoryModel, id: string) =>
        agent.requests.post(`/Category/${id}`, model),
    DisableOrEnableCategory: (ids: string[]) => agent.requests.post(`/Category/changestatus`, ids),
    DeleteCategory: (id: string) => agent.requests.delete(`/Category/${id}`),
};
