import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import {
    Avatar,
    Button,
    createStyles,
    ListItem,
    ListItemAvatar,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Checkbox,
    TablePagination,
    Paper,
    Theme,
    Toolbar,
} from '@material-ui/core';
import i18n from 'i18n-js';
import NumberFormat from 'react-number-format';

import { default as usersStore } from 'store/usersStore';
import globalStore from 'store/globalStore';
import { pagingOptions } from 'constants/arrays';
import commonStyles from 'config/commonStyles';
import agent from 'api/agent';
import { UserFilterModel } from 'api/models';
import { SearchInput, Text } from 'components/shared';
import logo from 'assets/user-logo.svg';
import { UsersState } from 'store/usersStore/reducer';

interface UsersTableProps {
    close: () => void;
    isContactPersons?: boolean;
    restaurant: {
        id: string;
        name: string;
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        root: {
            height: '100%',
            width: '100%',
            maxHeight: 'unset',
            justifyContent: 'flex-start',
            '& .MuiTabs-flexContainer': {
                height: '100%',
            },
            '& .MuiButtonBase-root.MuiTab-root': {
                minWidth: 'auto',
            },
            '& .MuiIconButton-label': {
                margin: '5px 0',
            },
        },
        toolbar: {
            paddingLeft: '16px !important',
            paddingRight: '18px !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& > div > div:first-child': {
                width: 70,
            },
            '& .MuiIconButton-root': {
                color: theme.palette.grey[500],
            },
        },
        usersTable: {
            '& .MuiTableCell-head': {
                paddingTop: 0,
                paddingRight: 0,
            },
            '& .MuiFormLabel-root, .MuiTypography-root': {
                fontSize: 14,
            },
            '& .MuiListItem-gutters': {
                paddingLeft: 0,
            },
            '& .MuiTableCell-root:not(:first-child)': {
                paddingLeft: 0,
            },
            '& .MuiTableCell-root:last-child': {
                paddingBottom: 16,
            },
            '& .MuiTableCell-body': {
                paddingTop: 0,
                paddingBottom: 0,
            },
            '& .MuiTableCell-body .MuiListItem-root.MuiListItem-gutters': {
                paddingTop: 0,
                paddingBottom: 0,
            },
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
            },
            '& .MuiListItemAvatar-root': {
                minWidth: 40,
            },
        },
        usersTableHeader: {
            ...commonStyles.flexRowSpaceBetween,
            width: '100%',
        },
    })
);

export default function UsersTable({
    close,
    isContactPersons = false,
    restaurant,
}: UsersTableProps) {
    const params = useParams<{ id: string }>();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(pagingOptions[0]);
    const [orderBy, setOrderBy] = useState('name');
    const [asc, setAsc] = useState(true);
    //const users = useSelector((state: ApplicationState) => state.users);
    const [users, setUsers] = useState<UsersState>({ users: [], count: 0 });
    const dispatch = useDispatch();
    const orderNames: string[] = ['name', 'restaurant', 'email', 'phoneNumber', 'position'];

    function getUsers(
        filterData: UserFilterModel = filter,
        page: number = 0,
        limit: number = rowsPerPage,
        isAsc: boolean = asc,
        order: string = orderBy
    ) {
        dispatch(globalStore.actionCreators.showSpiner());
        agent.Users.GetList(filterData, page, limit, isAsc, order)
            .then((result) => setUsers({ ...users, users: result.list, count: result.count }))
            .catch((error) => dispatch(globalStore.actionCreators.showToaster(error)))
            .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
    }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newRowsPerPage = +event.target.value;
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const defaultFilter: UserFilterModel = {
        name: '',
        restaurant: '',
        email: '',
        phoneNumber: '',
        position: '',
        roles: [],
    };

    const [filter, setFilter] = useState(defaultFilter);

    const handleOrder = (id: string) => {
        if (orderBy === id) {
            setAsc(!asc);
        } else {
            setOrderBy(id);
        }
    };
    const handleFilterChange = () => {
        setPage(0);
    };
    const [selected, setSelected] = useState<string[]>([]);
    const handleSingleSelect = (id: string) => {
        if (selected.includes(id)) {
            setSelected(selected.filter((x) => x !== id));
        } else {
            setSelected([...selected, id]);
        }
    };
    const handleGroupSelect = () => {
        if (selected.length === 0) {
            setSelected(users.users.map((x) => x.id));
        } else {
            setSelected([]);
        }
    };
    const submitSelect = () => {
        selected.length > 0 &&
            dispatch(
                usersStore.actionCreators.fromUsersToRestaurantEmployees({
                    restaurantId: restaurant.id,
                    restaurantName: restaurant.name,
                    usersIds: selected,
                    isContactPersons: isContactPersons,
                })
            );
    };
    function clearFilterField(value: keyof UserFilterModel) {
        if (filter.hasOwnProperty(value)) {
            const newFilter = { ...filter, [value]: '' };
            setFilter(newFilter);
        }
    }
    useEffect(() => {
        getUsers(filter, page, rowsPerPage, asc, orderBy);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, page, rowsPerPage, asc, orderBy]);
    return (
        <Paper className={classes.usersTable}>
            <Toolbar className={classes.toolbar}>
                <div className={classes.usersTableHeader}>
                    <div className={classes.flexRowSpaceBetween}>
                        <Typography
                            variant="h6"
                            id="tableTitle"
                            component="div"
                            className={classes.toolbarTitle}
                        >
                            {i18n.t('common.users')}
                        </Typography>
                    </div>
                    <div className={classes.toolbarActions}>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={close}
                            style={{ marginRight: 10 }}
                        >
                            {i18n.t('button.cancel')}
                        </Button>
                        <Button
                            disabled={selected.length === 0}
                            variant="contained"
                            color="primary"
                            onClick={submitSelect}
                        >
                            {i18n.t('button.addUser')}
                        </Button>
                    </div>
                </div>
            </Toolbar>
            <TableContainer className={classes.root}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow key="table-head">
                            <TableCell padding="checkbox" align={'left'}>
                                <Checkbox
                                    checked={
                                        users?.users?.length > 0 &&
                                        users?.users?.length === selected.length
                                    }
                                    onChange={handleGroupSelect}
                                    inputProps={{ 'aria-label': 'select all desserts' }}
                                    style={{ marginBottom: -10 }}
                                />
                            </TableCell>
                            <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                <TableSortLabel
                                    active={orderBy === orderNames[0]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[0])}
                                    style={{
                                        height: 9,
                                    }}
                                >
                                    <SearchInput
                                        value={filter.name}
                                        name={i18n.t('form.userName')}
                                        onChange={(value: string) =>
                                            setFilter({ ...filter, name: value })
                                        }
                                        onEnter={handleFilterChange}
                                        onReset={() => clearFilterField('name')}
                                    />
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                <TableSortLabel
                                    active={orderBy === orderNames[1]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[1])}
                                    style={{ height: 9 }}
                                >
                                    <SearchInput
                                        value={filter.restaurant}
                                        name={i18n.t('form.restaurant')}
                                        onChange={(value: string) =>
                                            setFilter({ ...filter, restaurant: value })
                                        }
                                        onEnter={handleFilterChange}
                                        onReset={() => clearFilterField('restaurant')}
                                    />
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                <TableSortLabel
                                    active={orderBy === orderNames[2]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[2])}
                                    style={{ height: 9 }}
                                >
                                    <SearchInput
                                        value={filter.email}
                                        name={i18n.t('form.email')}
                                        onChange={(value: string) =>
                                            setFilter({ ...filter, email: value })
                                        }
                                        onEnter={handleFilterChange}
                                        onReset={() => clearFilterField('email')}
                                    />
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                <TableSortLabel
                                    active={orderBy === orderNames[3]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[3])}
                                    style={{ height: 9 }}
                                >
                                    <SearchInput
                                        value={filter.phoneNumber}
                                        name={i18n.t('common.phone')}
                                        onChange={(value: string) =>
                                            setFilter({ ...filter, phoneNumber: value })
                                        }
                                        onEnter={handleFilterChange}
                                        onReset={() => clearFilterField('phoneNumber')}
                                    />
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={asc ? 'asc' : 'desc'}>
                                <TableSortLabel
                                    active={orderBy === orderNames[4]}
                                    direction={asc ? 'asc' : 'desc'}
                                    onClick={() => handleOrder(orderNames[4])}
                                    style={{ height: 9 }}
                                >
                                    <SearchInput
                                        value={filter.position}
                                        name={i18n.t('form.position')}
                                        onChange={(value: string) =>
                                            setFilter({ ...filter, position: value })
                                        }
                                        onEnter={handleFilterChange}
                                        onReset={() => clearFilterField('position')}
                                    />
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.users?.map((row) => (
                            <TableRow
                                key={row.id}
                                className={row.id}
                                style={!row.isEnabled ? { opacity: '30%' } : {}}
                            >
                                <TableCell padding="checkbox" align={'left'}>
                                    <Checkbox
                                        checked={selected.includes(row.id)}
                                        onChange={() => handleSingleSelect(row.id)}
                                        inputProps={{
                                            'aria-label': 'select all desserts',
                                        }}
                                    />
                                </TableCell>
                                <TableCell scope="row" align="left">
                                    <ListItem alignItems="center">
                                        <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src={logo} />
                                        </ListItemAvatar>
                                        <Text
                                            text={`${row.firstName} ${row.lastName}`}
                                            maxLength={15}
                                            fontSize={14}
                                        />
                                    </ListItem>
                                </TableCell>
                                <TableCell align="left">
                                    {row.restaurants.filter((x) => x.restaurantId === params.id)[0]
                                        ?.restaurantName ?? '-'}
                                </TableCell>
                                <TableCell align="left">
                                    <Text text={`${row.email}`} maxLength={30} fontSize={14} />
                                </TableCell>
                                <TableCell align="left">
                                    {row.phoneNumber ? (
                                        <NumberFormat
                                            format="+## (###) ### ## ##"
                                            displayType={'text'}
                                            value={row.phoneNumber}
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                                <TableCell align="left" colSpan={2}>
                                    {row.restaurants
                                        .filter((x) => x.restaurantId === params.id)[0]
                                        ?.positions.join(', ') ?? '-'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={pagingOptions}
                    component="div"
                    count={users.count}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={i18n.t('common.rowsPerPage')}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Paper>
    );
}
