import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField as MuiTextField,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import i18n from 'i18n-js';
import html2canvas from 'html2canvas';

import { default as restaurantStore } from 'store/restaurantDetailsStore';
import { ApplicationState } from 'store';
import { images } from 'assets';
import { Text } from 'components/shared';
import { PopupConfirmation } from 'components/shared';
import styles from '../styles';
import { QRData } from 'store/restaurantDetailsStore/reducer';
import { qrImageSettings, qrCodeSize } from '..';
import { OrderServices } from 'constants/enums';

const QRCode = require('qrcode.react');

interface QRListModalProps {
    isVisible: boolean;
    onClose: () => void;
    onEditClicked: (qrCode: QRData) => void;
    serviceName: OrderServices;
    downloadQrName: string;
}

export default function QrListModal({
    isVisible,
    onClose,
    onEditClicked,
    serviceName,
    downloadQrName,
}: QRListModalProps) {
    const dispatch = useDispatch();
    const qrCodes = useSelector((state: ApplicationState) => state.restaurantDetails.qrCodes);
    const [deletedQrIds, setDeletedQrIds] = useState<string[]>([]);
    const [qrTitleQuery, setQrTitleQuery] = useState('');
    const [qrPageQuery, setQrPageQuery] = useState('');
    const [downloadQrId, setDownloadQrId] = useState('');
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const classes = styles();

    function addOrRemoveDeleteQr(id: string) {
        if (deletedQrIds.includes(id)) {
            const filtered = deletedQrIds.filter((deleteQrId) => deleteQrId !== id);
            return setDeletedQrIds(filtered);
        }
        setDeletedQrIds([...deletedQrIds, id]);
    }

    function getServiceQrs(amount = 0) {
        let filtered = qrCodes?.filter((qrCode) => qrCode.service === serviceName);
        if (amount) {
            return filtered.slice(0, amount);
        }
        filtered = filtered.filter(
            (qrCode) => qrCode.name.toLowerCase().indexOf(qrTitleQuery.toLowerCase()) >= 0
        );
        filtered = filtered.filter(
            (qrCode) => qrCode.area.toLowerCase().indexOf(qrPageQuery.toLowerCase()) >= 0
        );
        return filtered;
    }

    function deleteQrs() {
        dispatch(restaurantStore.actionCreators.deleteQrCode(deletedQrIds));
        setDeletedQrIds([]);
    }

    function selectOrDeSelectAllQrs() {
        if (deletedQrIds.length !== getServiceQrs().length) {
            const qrIds: string[] = [];
            getServiceQrs().forEach((qrCode) => {
                // @ts-ignore
                qrIds.push(qrCode.id);
            });
            setDeletedQrIds(qrIds);
        } else {
            setDeletedQrIds([]);
        }
    }

    async function downloadQRCode(qrCode: any) {
        const containerId = `qrContainer-${qrCode.id}`;
        setDownloadQrId(qrCode.id);

        await new Promise((resolve) => setTimeout(() => resolve('done'), 500));

        html2canvas(document.getElementById(containerId) as HTMLCanvasElement, {
            onclone: (document, element) => {
                element.style.display = 'flex';
            },
        })
            .then((canvas) => {
                const pngUrl = canvas
                    .toDataURL('image/png')
                    .replace('image/png', 'image/octet-stream');
                let downloadLink = document.createElement('a');
                downloadLink.href = pngUrl;
                downloadLink.download = `${qrCode.service}-${qrCode.name}.png`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
            .catch((e) => alert(e))
            .finally(() => setDownloadQrId(''));
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={isVisible}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <div className={classes.flexRowSpaceBetween}>
                    <Typography variant="h6" align="left" className={classes.fontWeight500}>
                        {i18n.t('restaurant.qrCodes')}
                    </Typography>
                    <div>
                        <Button
                            onClick={() => {
                                setDeletedQrIds([]);
                                onClose();
                            }}
                            type="button"
                            color="primary"
                            className={classes.mRight20}
                        >
                            {i18n.t('button.cancel')}
                        </Button>
                        <Button
                            onClick={() => setShowDeletePopup(true)}
                            disabled={deletedQrIds.length === 0}
                            type="button"
                            color="primary"
                            variant="contained"
                            className={classes.saveRemoveButton}
                        >
                            {i18n.t('common.removeSelected')}
                        </Button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent className={classes.qrListContainer}>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead style={{ opacity: 0.5 }}>
                            <TableRow key="table-head">
                                <TableCell className={classes.alignCenter}>
                                    <Checkbox
                                        checked={
                                            deletedQrIds.length === getServiceQrs().length &&
                                            deletedQrIds.length !== 0
                                        }
                                        onChange={selectOrDeSelectAllQrs}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                    <MuiTextField
                                        onChange={(e) => setQrTitleQuery(e.target.value)}
                                        placeholder={i18n.t('restaurant.qrTitle')}
                                        InputProps={{
                                            endAdornment: (
                                                <img src={images.icons.search} alt="search-icon" />
                                            ),
                                            disableUnderline: true,
                                        }}
                                        className={classes.qrListHeaderInput}
                                    />
                                </TableCell>
                                <TableCell>
                                    <MuiTextField
                                        onChange={(e) => setQrPageQuery(e.target.value)}
                                        placeholder={i18n.t('restaurant.page')}
                                        InputProps={{
                                            endAdornment: (
                                                <img src={images.icons.search} alt="search-icon" />
                                            ),
                                            disableUnderline: true,
                                        }}
                                        className={classes.qrListHeaderInput}
                                    />
                                </TableCell>
                                <TableCell align={'right'}>
                                    <img
                                        src={images.icons.removeCircleDisabled}
                                        alt="remove-icon"
                                        className={classes.actionBlock}
                                    />
                                    <span>{i18n.t('restaurant.action')}</span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getServiceQrs().map((qrCode, i) => (
                                <TableRow key={i}>
                                    <div
                                        id={`qrContainer-${qrCode?.id}`}
                                        className={classes.qrDownloadContainer}
                                    >
                                        <span className={classes.qrCodeTopText}>
                                            {downloadQrName}
                                        </span>
                                        {downloadQrId === qrCode.id && (
                                            <QRCode
                                                className={classes.qrCodeDownload}
                                                id={qrCode.id}
                                                value={qrCode.url}
                                                imageSettings={qrImageSettings}
                                                size={qrCodeSize}
                                                includeMargin={true}
                                                level={'Q'}
                                            />
                                        )}
                                        <span className={classes.qrCodeDownloadText}>
                                            {qrCode.name}
                                        </span>
                                    </div>
                                    <TableCell className={classes.alignCenter}>
                                        <Checkbox
                                            // @ts-ignore
                                            checked={deletedQrIds.includes(qrCode.id)}
                                            // @ts-ignore
                                            onChange={() => addOrRemoveDeleteQr(qrCode.id)}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        <QRCode value={''} size={20} className={classes.listQr} />
                                        <Text text={qrCode.name} maxLength={30} />
                                    </TableCell>
                                    <TableCell>
                                        <span>{qrCode.area}</span>
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <img
                                            onClick={() => downloadQRCode(qrCode)}
                                            src={images.icons.fileDownloadDisabled}
                                            alt="file-download-icon"
                                            className={` ${classes.cursorHover} ${classes.fileDownloadIcon}`}
                                        />
                                        <img
                                            // @ts-ignore
                                            onClick={() => addOrRemoveDeleteQr(qrCode.id)}
                                            src={images.icons.removeCircleDisabled}
                                            alt="remove-icon"
                                            className={`${classes.mRight50} ${classes.cursorHover}`}
                                        />
                                        <img
                                            onClick={() => onEditClicked(qrCode)}
                                            src={images.icons.edit}
                                            alt="edit-icon"
                                            className={` ${classes.cursorHover}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {getServiceQrs().length === 0 && (
                                <div className={classes.noQrContainer}>
                                    <QRCode
                                        className={classes.noQrCode}
                                        value={''}
                                        size={138}
                                        includeMargin={true}
                                    />
                                    <h6 className={classes.noQrText}>
                                        {i18n.t('restaurant.noQrLeft')}
                                    </h6>
                                    <h6 className={classes.noQrCreateText}>
                                        {i18n.t('restaurant.noQrCreateNew')}
                                    </h6>
                                </div>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>

            <PopupConfirmation
                open={showDeletePopup}
                close={() => setShowDeletePopup(false)}
                title={i18n.t('restaurant.removeQr')}
                description={i18n.t('restaurant.removeQrWarning')}
                activeBtn={i18n.t('common.remove')}
                action={deleteQrs}
            />
        </Dialog>
    );
}
