import { Action, Reducer } from 'redux';
import { IngredientsAction } from './actions';

export interface IngredientCategoryData {
    id: string;
    name: string;
    ingredients: any[];
    isDisabled: boolean;
}

export interface Allergen {
    id: string;
    name: string;
    icon: string;
}

export interface IngredientsState {
    ingredients: any[];
    ingredientsCount: number;
    categories: IngredientCategoryData[];
    categoriesCount: number;
    allergies: Allergen[];
}

//REDUCER
const initialState = {
    ingredients: [],
    ingredientsCount: 13,
    categories: [],
    categoriesCount: 13,
    allergies: [],
};

export const reducer: Reducer<IngredientsState> = (
    state: IngredientsState | undefined,
    incomingAction: Action
): IngredientsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as IngredientsAction;
    switch (action.type) {
        case 'GET_INGREDIENTS_SUCCESS': {
            return {
                ...state,
                ingredients: action.data,
                ingredientsCount: action.count,
            };
        }
        case 'CREATE_INGREDIENT_SUCCESS': {
            const newIngredients = [...state.ingredients, action.data];
            return {
                ...state,
                ingredients: newIngredients,
            };
        }
        case 'UPDATE_INGREDIENT_SUCCESS': {
            const indexOfIngredient = state.ingredients.findIndex(
                (val) => val.id === action.data.id
            );
            const newIngredients = [...state.ingredients];
            newIngredients[indexOfIngredient] = action.data;

            const updatedCategories = state.categories.map((category) => {
                if (category.id === action.data.categoryId) {
                    return {
                        ...category,
                        ingredients: [...category.ingredients, action.data],
                    };
                } else {
                    return {
                        ...category,
                        ingredients: category.ingredients.filter((i) => i.id !== action.data.id),
                    };
                }
            });
            return {
                ...state,
                ingredients: newIngredients,
                categories: updatedCategories,
            };
        }
        case 'REMOVE_INGREDIENT_SUCCESS': {
            return {
                ...state,
                ingredients: state.ingredients.filter((i) => i.id !== action.data.id),
            };
        }
        case 'GET_INGREDIENT_CATEGORIES_SUCCESS': {
            return {
                ...state,
                categories: action.data,
                categoriesCount: action.count,
            };
        }
        case 'CREATE_INGREDIENT_CATEGORY_SUCCESS': {
            return {
                ...state,
                categories: [...state.categories, action.data],
            };
        }
        case 'UPDATE_INGREDIENT_CATEGORY_SUCCESS': {
            const indexOfCategory = state.categories.findIndex((val) => val.id === action.data.id);
            const newCategories = [...state.categories];
            newCategories[indexOfCategory] = {
                ...newCategories[indexOfCategory],
                name: action.data.name,
            };
            return {
                ...state,
                categories: newCategories,
            };
        }
        case 'DELETE_INGREDIENT_CATEGORY_SUCCESS': {
            return {
                ...state,
                categories: state.categories.filter(
                    (eachCategory) => eachCategory.id !== action.id
                ),
            };
        }
        case 'DISABLE_INGREDIENT_CATEGORY_SUCCESS': {
            const indexOfCategory = state.categories.findIndex((val) => val.id === action.id);
            const newCategories = [...state.categories];
            newCategories[indexOfCategory].isDisabled = !newCategories[indexOfCategory].isDisabled;
            return {
                ...state,
                categories: newCategories,
            };
        }
        case 'GET_ALLERGIES_SUCCESS': {
            return { ...state, allergies: action.allergies };
        }
        default:
            return state;
    }
};
