import { AppThunkAction } from '../index';

import i18n from 'i18n-js';

import agent from 'api/agent';
import globalStore from '../globalStore';
import { CategoryAction } from './actions';
import { CreateCategoryModel } from './reducer';

export const actionCreators = {
    getCategoryDetails:
        (asc: boolean = false, orderBy: string = 'name'): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_CATEGORY_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Category.GetCategoryDetails(asc, orderBy)
                .then((res) => {
                    dispatch({
                        type: 'GET_CATEGORY_DETAILS_SUCCESS',
                        data: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_CATEGORY_DETAILS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    createCategory:
        (model: CreateCategoryModel): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            dispatch({
                type: 'CREATE_CATEGORY_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Category.CreateCategory(model)
                .then((res) => {
                    dispatch({
                        type: 'CREATE_CATEGORY_SUCCESS',
                        id: res.id,
                        name: res.name,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccessfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'CREATE_CATEGORY_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    editCategory:
        (model: CreateCategoryModel, categoryId: string): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            dispatch({
                type: 'EDIT_CATEGORY_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Category.EditCategory(model, categoryId)
                .then(() => {
                    // For now better to get updated categories from server
                    // Because dont know how some cases would be implemented
                    // @ts-ignore
                    actionCreators.getCategoryDetails()(dispatch);
                    dispatch({
                        type: 'EDIT_CATEGORY_SUCCESS',
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'EDIT_CATEGORY_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    disableOrEnableCategory:
        (categoriesIds: string[]): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            dispatch({
                type: 'DISABLE_OR_ENABLE_CATEGORIES_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Category.DisableOrEnableCategory(categoriesIds)
                .then((result) => {
                    dispatch({
                        type: 'DISABLE_OR_ENABLE_CATEGORIES_SUCCESS',
                        result,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'DISABLE_OR_ENABLE_CATEGORIES_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    deleteCategory:
        (categoryId: string): AppThunkAction<CategoryAction> =>
        (dispatch) => {
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Category.DeleteCategory(categoryId)
                .then(() => {
                    dispatch({
                        type: 'DELETE_CATEGORY_SUCCESS',
                        payload: { categoryId },
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};
