import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import i18n from 'i18n-js';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { ListItemIcon } from '@material-ui/core';

import { default as menusStore } from 'store/menusStore';
import { ApplicationState } from 'store';
import { BasicThreeDotsMenu, PopupConfirmation, Text } from 'components/shared';
import { MenuType, Positions, Roles } from 'constants/enums';
import { MenuEditModal } from '../../Modals/MenuEditModal';
import { getHourAndMinutes } from 'helpers/datetimeHelper';
import { useStyles } from '../styles';
import { images } from 'assets';
import MenuCopyModal from 'components/menu/Modals/MenuCopyModal';
import { MenuDetailsData } from 'store/menuDetailsStore/reducer';
import { MenuData } from 'store/menusStore/reducer';

export interface MenuPreviewProps {
    menu: MenuData;
    disable: any;
    edit: any;
    menuType: string;
    showDetails?: (id: string) => void;
    setName?: (name: string) => void;
}

export default function MenuPreviewItem(props: MenuPreviewProps) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user);
    const currentPosition = user.restaurants.find(
        (i) => i.id === user.currentRestaurantId
    )?.currentPosition;

    const [showEdit, setEdit] = React.useState<boolean>(false);
    const [showConfirmation, setConfirmation] = React.useState<number>(0);
    const [showCopyDialog, setShowCopyDialog] = useState(false);
    const isIngredients = props.menuType === 'ING';
    const isDailyMenu = props.menu.type === MenuType.Daily;
    const isManager = user.role === Roles.OWNER && currentPosition === Positions.MANAGER;

    function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        isIngredients ? history.push('/ingredients') : handleDetails(e);
    }

    function handleClickOpen() {
        setEdit(true);
    }

    function handleClose() {
        setEdit(false);
    }

    function handleConfirmationClose() {
        setConfirmation(0);
    }

    function disableMenu() {
        props.disable(props.menu.id);
        handleConfirmationClose();
    }

    function deleteMenu() {
        dispatch(menusStore.actionCreators.deleteMenu(props.menu.id));
        handleConfirmationClose();
    }

    function prepareBackground() {
        let styles;
        if (props.menu.photo && isIngredients) {
            styles = {
                background: `url(${props.menu.photo})`,
            };
        } else if (props.menu.photo) {
            styles = {
                background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${props.menu.photo})`,
            };
        }

        return styles;
    }

    function handleDetails(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        e.preventDefault();
        if (props.showDetails) {
            if (props.menu.isEnabled || user.role === Roles.OWNER) {
                !!props.setName && props.setName(props.menu.name);
                props.showDetails(props.menu.id);
            }
        } else {
            history.push(`/menu/${props.menu.id}`);
        }
    }

    function getThreeDotItems() {
        const items = [
            isIngredients ? (
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => {
                        handleClickOpen();
                    }}
                >
                    <ListItemIcon>
                        <img src={images.icons.edit} alt="edit" height={18} />
                    </ListItemIcon>
                    <Typography variant="inherit">{i18n.t('common.edit')}</Typography>
                </div>
            ) : (
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => {
                        handleClickOpen();
                    }}
                >
                    <ListItemIcon>
                        <img src={images.icons.edit} alt="edit" height={18} />
                    </ListItemIcon>
                    <Typography variant="inherit">{i18n.t('common.edit')}</Typography>
                </div>
            ),
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => {
                    setConfirmation(1);
                }}
            >
                <ListItemIcon>
                    <img src={images.icons.removeCircleDisabled} alt="edit" height={18} />
                </ListItemIcon>
                <Typography variant="inherit">
                    {props.menu.isEnabled ? i18n.t('common.disable') : i18n.t('common.enable')}
                </Typography>
            </div>,
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => {
                    setShowCopyDialog(true);
                }}
            >
                <ListItemIcon>
                    <img src={images.icons.copy} alt="copy" height={18} />
                </ListItemIcon>
                <Typography variant="inherit">
                    {i18n.t('restaurant.copyAndMakeSpecialMenu')}
                </Typography>
            </div>,
        ];
        !isDailyMenu &&
            isManager &&
            items.push(
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={(e) => {
                        setConfirmation(2);
                    }}
                >
                    <ListItemIcon>
                        <img src={images.icons.removeCircleDisabled} alt="edit" height={18} />
                    </ListItemIcon>
                    <Typography variant="inherit">{i18n.t('common.delete')}</Typography>
                </div>
            );
        return items;
    }

    return (
        <div>
            <Card
                onClick={(e) => {
                    handleClick(e);
                }}
                className={
                    props.menu.photo
                        ? `${classes.menuPreviewItemRoot} ${classes.imageBased} ${
                              !props.menu.isEnabled && classes.disabled
                          }`
                        : `${classes.menuPreviewItemRoot} ${
                              !props.menu.isEnabled && classes.disabled
                          }`
                }
                style={prepareBackground()}
            >
                <CardHeader
                    action={
                        user.role === Roles.OWNER &&
                        !isIngredients && <BasicThreeDotsMenu items={getThreeDotItems()} />
                    }
                    title={<Text text={props.menu.name} maxLength={20} fontSize={21} />}
                    className={!!props.menu.photo ? classes.whiteThreeDots : ''}
                    //Description hidded for now but maybe will be showed in feature
                    //subheader={props.menu.description}
                />
                <CardActions disableSpacing>
                    <CardContent>
                        {!isIngredients && (
                            <Typography variant="body2" color="textSecondary" component="p">
                                {props.menu.availableFrom &&
                                props.menu.availableTo &&
                                props.menu.availableFrom !== props.menu.availableTo
                                    ? `${getHourAndMinutes(
                                          props.menu.availableFrom
                                      )} - ${getHourAndMinutes(props.menu.availableTo)}`
                                    : '24/7'}
                            </Typography>
                        )}
                    </CardContent>
                    <CardContent>
                        <Typography variant="subtitle2" color="textSecondary" component="p">
                            {`${props.menu.dishesCount} items`}
                        </Typography>
                    </CardContent>
                </CardActions>
            </Card>
            <MenuEditModal
                menu={props.menu}
                isOpen={showEdit}
                handleClose={handleClose}
                isDetails={false}
            />
            <PopupConfirmation
                open={showConfirmation > 0}
                close={handleConfirmationClose}
                action={showConfirmation === 1 ? disableMenu : deleteMenu}
                title={
                    showConfirmation === 1
                        ? props.menu.isEnabled
                            ? i18n.t('common.disable')
                            : i18n.t('common.enable')
                        : i18n.t('common.delete')
                }
                description={
                    showConfirmation === 1
                        ? props.menu.isEnabled
                            ? i18n.t('confirmation.disableMenu')
                            : i18n.t('confirmation.enableMenu')
                        : i18n.t('confirmation.deleteMenu')
                }
                activeBtn={
                    showConfirmation === 1
                        ? props.menu.isEnabled
                            ? i18n.t('common.disable')
                            : i18n.t('common.enable')
                        : i18n.t('common.delete')
                }
            />
            <MenuCopyModal
                menu={props.menu as unknown as MenuDetailsData}
                isOpen={showCopyDialog}
                onClose={() => setShowCopyDialog(false)}
            />
        </div>
    );
}
