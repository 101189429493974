import { createStyles, makeStyles, Theme } from '@material-ui/core';
import commonStyles from '../../../config/commonStyles';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        qrContainer: {
            padding: '15px',
        },
        qrItemContainer: {
            padding: '10px 20px',
            width: '45.5%',
            boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
            borderRadius: '4px',
            '&:hover': {
                cursor: 'pointer',
                boxShadow:
                    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.5) 0px 1px 3px 1px;',
            },
        },
        qrTextContainer: {
            width: '35%',
        },
        qrItemTitle: {
            fontSize: '19px',
            fontWeight: '500',
            marginBottom: '5px',
            zIndex: 2,
        },
        qrCodeContainer: {
            display: 'flex',
            zIndex: 1,
            alignItems: 'center',
            justifyContent: ' center',
            height: '80%',
            width: '110px',
            alignSelf: 'center',
            padding: '4px 15px',
            borderRadius: '8px',
            backgroundColor: theme.palette.common.white,
            boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
        },
        addQrCodeContainer: {
            display: 'flex',
            width: '110px',
            margin: 'auto',
            alignItems: 'center',
            justifyContent: ' center',
            alignSelf: 'center',
            padding: '8px',
            borderRadius: '8px',
            boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
        },
        homeDeliveryIcon: {
            width: '39px',
        },
        takeAwayIcon: {
            width: '31px',
        },
        takeOutIcon: {
            width: '40px',
        },
        addQrText: {
            color: theme.palette.secondary.dark,
            fontWeight: '500',
        },
        qrListTable: {
            width: '936px',
        },
        saveRemoveButton: {
            width: '200px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.error.light,
        },
        actionBlock: {
            marginRight: '33px',
            marginBottom: '-5px',
        },
        removeButton: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.error.light,
        },
        loaderContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10%',
        },
        listQr: {
            margin: '0 15px 0 5px',
        },
        qrListHeaderInput: {
            width: '100px',
            color: theme.palette.grey['600'],
        },
        qrListContainer: {
            height: '645px',
        },
        fileDownloadIcon: {
            margin: '0 35px -3px 0',
        },
        qrDownloadContainer: {
            display: 'none',
            padding: '15px 2px',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 340,
            background: 'linear-gradient(180deg, #ffd79a 9.9%, #ff9f9f 100%)',
        },
        qrCodeDownload: {
            borderRadius: 8,
        },
        qrCodeTopText: {
            margin: '-5px 0 10px 0',
            textAlign: 'center',
            fontWeight: '700',
            inlineSize: '275px',
            overflowWrap: 'break-word',
        },
        qrCodeDownloadText: {
            margin: '6px 0 -5px 0',
            textAlign: 'center',
            fontWeight: '700',
            inlineSize: '275px',
            overflowWrap: 'break-word',
        },
        noQrContainer: {
            position: 'absolute',
            top: '42%',
            left: '42%',
        },
        noQrCode: {
            opacity: 0.35,
        },
        noQrText: {
            fontSize: '16px',
            margin: 0,
            marginLeft: '-23px',
            fontWeight: '500',
        },
        noQrCreateText: {
            fontSize: '16px',
            margin: '10px 0 0 -35px',
            fontWeight: '500',
            opacity: 0.6,
        },
    })
);

export default styles;
