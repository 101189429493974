import {
    MenuCreateModel,
    RenameSectionModel,
    AddSectionModel,
    AddSectionDailyMenyModel,
    DeleteSectionModel,
    DishCreateModel,
    DeleteDishModel,
    AddExistingDishModel,
    IdModel,
    EditSectionDailyMenyModel,
    SectionColorModel,
    CopyMenuModel,
} from 'api/models';
import agent, { prepareHeaders } from 'api/agent';

export const Menus = {
    Create: (menuModel: MenuCreateModel) =>
        agent.requests.put('/Menu/create', menuModel, prepareHeaders()),
    GetList: (params = {}) => agent.requests.get('/Menu/list', { params }),
    Disable: (id: string) => agent.requests.get(`/Menu/disable/${id}`),
    Delete: (id: string) => agent.requests.delete('/Menu/delete', { id }),
    GetDetails: (id: string) => agent.requests.get(`/Menu/${id}`),
    RenameSection: (id: string, model: RenameSectionModel) =>
        agent.requests.post(`/Menu/renameSection?id=${id}`, model),
    AddSection: (model: AddSectionModel) => agent.requests.post('/Menu/addSection', model),
    AddDailyMenuSection: (model: AddSectionDailyMenyModel) =>
        agent.requests.post('/Menu/addDailyMenySection', model),
    DeleteSection: (model: DeleteSectionModel) =>
        agent.requests.delete('/Menu/deleteSection', model),
    CreateDish: (model: DishCreateModel) =>
        agent.requests.put('/Dish/create', model, prepareHeaders()),
    RemoveDish: (model: DeleteDishModel) => agent.requests.post('/Dish/removefromsection', model),
    DisableDish: (id: string) =>
        agent.requests.post('/Dish/disable', {
            id,
        }),
    GetDishList: () => agent.requests.get('/Dish/list'),
    AddExistingDish: (model: AddExistingDishModel) =>
        agent.requests.put('/Dish/addexisting', model, prepareHeaders()),
    EditDish: (model: DishCreateModel, id: string) =>
        agent.requests.post(`/Dish/update/${id}`, model),
    Edit: (menuModel: MenuCreateModel, id: string) => agent.requests.post(`/Menu/${id}`, menuModel),
    GetMenusDetails: (id?: string) =>
        agent.requests.get(`/Menu/getMenusDetails${id ? '/' + id : ''}`),
    DeleteDish: (model: IdModel) => agent.requests.delete('/Dish/delete', model),
    GetMenuDishes: (id: string) => agent.requests.get(`/Menu/getMenuDishes/${id}`),
    DisableEnableDailyMenuSection: (id: string) =>
        agent.requests.post(`/Menu/disableEnableDailyMenuSection?id=${id}`),
    EditDailyMenuSection: (id: string, model: EditSectionDailyMenyModel) =>
        agent.requests.post(`/Menu/updateDailyMenuSection?id=${id}`, model),
    ReorderMenus: (ids: string[]) => agent.requests.post('/Menu/reorder', ids),
    ReorderSections: (ids: string[], isDaily: boolean = false) =>
        agent.requests.post(`/Menu/sections/reorder?isDaily=${isDaily}`, ids),
    ResorderDishes: (sectionId: string, ids: string[], isDaily: boolean = false) =>
        agent.requests.post(`/Menu/sections/${sectionId}/reorderDishes?isDaily=${isDaily}`, ids),
    SetSectionColor: (sectionId: string, model: SectionColorModel) =>
        agent.requests.post(`/Menu/sections/${sectionId}/color`, model),
    SetSectionDishColor: (sectionId: string, dishId: string, model: SectionColorModel) =>
        agent.requests.post(`/Menu/sections/${sectionId}/dishes/${dishId}/color`, model),
    CopySectionDish: (sectionId: string, dishId: string) =>
        agent.requests.post(`/Menu/sections/${sectionId}/dishes/${dishId}/copy`),
    CopyMenu: (menuId: string, model: CopyMenuModel) =>
        agent.requests.put(`/Menu/${menuId}/copy`, model, prepareHeaders()),
    SetSectionPhoto: (sectionId: string, photo: string) =>
        agent.requests.post(`/Menu/sections/${sectionId}/photo`, { photo }),
};
