import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Paper,
    TablePagination,
    Tabs,
    Tab,
    TableSortLabel,
} from '@material-ui/core';
import i18n from 'i18n-js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { default as ingredientsStore } from 'store/ingredientsStore';
import {
    BasicThreeDotsMenu,
    MultiSelectInputDropdown,
    SearchInput,
    PopupConfirmation,
} from 'components/shared';
import { pagingOptions } from 'constants/arrays';
import { IngredientModel } from 'api/models';
import { useStyles } from './styles';
import { useAppSelector } from 'index';
import { CategoryModal, EditIngredientModal, CategoryIngredientsModal } from './components';
import { IngredientCategoryData } from 'store/ingredientsStore/reducer';
import globalStore from 'store/globalStore';

export default function Ingredients() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const allergens = useAppSelector((state) => state.ingredients.allergies);
    const ingredients = useAppSelector((state) => state.ingredients.ingredients);
    const ingredientsCount = useAppSelector((state) => state.ingredients.ingredientsCount);
    const categories = useAppSelector((state) => state.ingredients.categories);
    const categoriesCount = useAppSelector((state) => state.ingredients.categoriesCount);
    const lang = useAppSelector((state) => state.global.language);

    const [showIngredientModal, setShowIngredientModal] = useState(false);
    const [showRemoveIngredientModal, setShowRemoveIngredientModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showRemoveCategoryModal, setShowRemoveCategoryModal] = useState(false);
    const [showCategoryIngredientsModal, setShowCategoryIngredientsModal] = useState(false);
    const [ingredientsPage, setIngredientsPage] = useState(0);
    const [categoriesPage, setCategoriesPage] = useState(0);
    const [sortIngredients, setSortIngredients] = useState<'asc' | 'desc' | undefined>(undefined);

    const [ingredientsRowsPerPage, setIngredientsRowsPerPage] = useState(
        pagingOptions[ingredientsPage]
    );
    const [categoriesRowsPerPage, setCategoriesRowsPerPage] = useState(
        pagingOptions[categoriesPage]
    );
    const [categoriesNameSearchText, setCategoriesNameSearchText] = useState('');

    const [view, setView] = useState(1);
    const [selectedIngredient, setSelectedIngredient] =
        useState<IngredientModel | undefined>(undefined);
    const [selectedCategory, setSelectedCategory] =
        useState<IngredientCategoryData | undefined>(undefined);

    const [ingredientFilterText, setIngredientFilterText] = useState('');
    const [categoryFilters, setCategoryFilters] = useState<string[]>([]);

    useEffect(() => {
        dispatch(ingredientsStore.actionCreators.getIngredients(lang));
        dispatch(ingredientsStore.actionCreators.getCategories(lang));
        dispatch(ingredientsStore.actionCreators.getAllergies(lang));
    }, [lang]);

    function handleIngredientsChangePage(event: unknown, newPage: number) {
        setIngredientsPage(newPage);
        dispatch(ingredientsStore.actionCreators.getIngredients(lang, newPage));
    }

    function handleIngredientsChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        let newRowsPerPage = +event.target.value;
        setIngredientsRowsPerPage(newRowsPerPage);
        setIngredientsPage(0);
        dispatch(ingredientsStore.actionCreators.getIngredients(lang, 0, newRowsPerPage));
    }

    function handleCategoriesChangePage(event: unknown, newPage: number) {
        setCategoriesPage(newPage);
        dispatch(ingredientsStore.actionCreators.getCategories(lang, newPage));
    }

    function handleCategoriesChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        let newRowsPerPage = +event.target.value;
        setCategoriesRowsPerPage(newRowsPerPage);
        setCategoriesPage(0);
        dispatch(ingredientsStore.actionCreators.getCategories(lang, 0, newRowsPerPage));
    }

    function changeTab(event: React.ChangeEvent<{}>, view: number) {
        setView(view);
    }

    function getCategoryThreeDotsMenu(category: IngredientCategoryData) {
        const items = [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={(e) => {
                    setSelectedCategory(category);
                    setShowCategoryModal(true);
                }}
            >
                {i18n.t('common.edit')}
            </div>,
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={(e) => {
                    dispatch(ingredientsStore.actionCreators.disableEnableCategory(category.id));
                }}
            >
                {i18n.t(`${category.isDisabled ? 'common.enable' : 'common.disable'}`)}
            </div>,
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={(e) => {
                    if (category.ingredients.length > 0) {
                        dispatch(
                            globalStore.actionCreators.showToaster(
                                'error',
                                i18n.t('common.wantDeleteCategoryError')
                            )
                        );
                        return;
                    }
                    setSelectedCategory(category);
                    setShowRemoveCategoryModal(true);
                }}
            >
                {i18n.t('common.delete')}
            </div>,
        ];

        return items;
    }

    function filterCategory(category: IngredientCategoryData) {
        return category.name.toLowerCase().includes(categoriesNameSearchText);
    }

    function filterIngredients(ingredient: { name: string; categoryId: string }) {
        const categoryId = ingredient.categoryId;
        if (categoryFilters.length > 0) {
            if (ingredientFilterText.length > 0) {
                return (
                    ingredient.name.toLowerCase().includes(ingredientFilterText.toLowerCase()) &&
                    categoryFilters.includes(categoryId)
                );
            }
            return categoryFilters.includes(categoryId);
        }
        if (ingredient.name.toLowerCase().includes(ingredientFilterText.toLowerCase())) {
            return true;
        }
    }

    function getCategoryNameOfIngredient(ingredient: { categoryId: string }) {
        const category = categories.find((each) => each.id === ingredient.categoryId);
        return category?.name ?? '';
    }

    function getAllergenIcons(ingredient: IngredientModel) {
        return ingredient.allergens?.map((id: string) => {
            const allergen = allergens.find((each) => each.id === id);
            return (
                <img
                    key={id}
                    src={allergen?.icon}
                    alt={allergen?.name}
                    style={{ height: '24px', marginLeft: 7 }}
                />
            );
        });
    }

    function onRemoveIngredient() {
        if (!selectedIngredient) return;
        dispatch(ingredientsStore.actionCreators.removeIngredient(selectedIngredient));
        setShowRemoveIngredientModal(false);
    }

    function onRemoveCategory() {
        if (!selectedCategory) return;
        dispatch(ingredientsStore.actionCreators.deleteCategory(selectedCategory.id));
    }

    return (
        <div className={classes.root}>
            <CategoryModal
                defaultValues={selectedCategory}
                isVisible={showCategoryModal}
                onClose={() => {
                    setShowCategoryModal(false);
                    setTimeout(() => setSelectedCategory(undefined), 250);
                }}
            />
            <EditIngredientModal
                defaultValues={selectedIngredient}
                isVisible={showIngredientModal}
                onClose={() => {
                    setShowIngredientModal(false);
                    setTimeout(() => setSelectedIngredient(undefined), 250);
                }}
            />
            <PopupConfirmation
                open={showRemoveIngredientModal}
                close={() => setShowRemoveIngredientModal(false)}
                title={i18n.t('common.wantDeleteIngredient')}
                description={''}
                activeBtn={i18n.t('common.delete')}
                action={onRemoveIngredient}
            />
            <PopupConfirmation
                open={showRemoveCategoryModal}
                close={() => setShowRemoveCategoryModal(false)}
                title={i18n.t('common.wantDeleteCategory')}
                description={''}
                activeBtn={i18n.t('common.delete')}
                action={onRemoveCategory}
            />
            <CategoryIngredientsModal
                isVisible={showCategoryIngredientsModal}
                category={selectedCategory}
                onClose={() => {
                    setShowCategoryIngredientsModal(false);
                    setTimeout(() => setSelectedCategory(undefined), 250);
                }}
            />

            <Toolbar
                style={{
                    paddingBottom: '10px',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className={classes.iconButton}
                        onClick={() => history.goBack()}
                    >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                    <Tabs
                        className={`${classes.tab}`}
                        value={view}
                        onChange={changeTab}
                        aria-label="wrapped label tabs example"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab key="1" value={1} label={i18n.t('common.ingredients')} />
                        <Tab key="2" value={2} label={i18n.t('common.categories')} />
                    </Tabs>
                </div>

                {view === 1 && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 5, width: '212px' }}
                        onClick={() => setShowIngredientModal(true)}
                    >
                        {`+ ${i18n.t('restaurant.newIngredient').toUpperCase()}`}
                    </Button>
                )}
                {view === 2 && (
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 5, minWidth: '212px' }}
                        onClick={() => setShowCategoryModal(true)}
                    >
                        {`+ ${i18n.t('common.newCategory').toUpperCase()}`}
                    </Button>
                )}
            </Toolbar>

            {/* Ingredients */}
            {view === 1 && (
                <Paper>
                    <TableContainer id="container">
                        <Table className={classes.root} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'20%'}>
                                        <TableSortLabel
                                            active={!!sortIngredients}
                                            direction={sortIngredients ? sortIngredients : 'desc'}
                                            onClick={() =>
                                                setSortIngredients(
                                                    sortIngredients === 'asc' ? 'desc' : 'asc'
                                                )
                                            }
                                        >
                                            <SearchInput
                                                style={{ marginBottom: 6 }}
                                                fontSize={16}
                                                name={i18n.t('common.ingredients')}
                                                onChange={(val) => setIngredientFilterText(val)}
                                                value={ingredientFilterText}
                                                onFocus={() => {
                                                    let newRowsPerPage = 250;
                                                    setIngredientsRowsPerPage(newRowsPerPage);
                                                    setIngredientsPage(0);
                                                    dispatch(
                                                        ingredientsStore.actionCreators.getIngredients(
                                                            lang,
                                                            0,
                                                            newRowsPerPage,
                                                            true
                                                        )
                                                    );
                                                    setSortIngredients('desc');
                                                }}
                                                onEnter={() => ''}
                                                onReset={() => setIngredientFilterText('')}
                                            />
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell width={'20%'}>
                                        <MultiSelectInputDropdown
                                            style={{ marginTop: 10 }}
                                            options={categories}
                                            inputLabel={i18n.t('common.category')}
                                            onChange={(values) =>
                                                setCategoryFilters([...values.map((val) => val.id)])
                                            }
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCellHeader} width={'20%'}>
                                        <div className={classes.tableCellHeaderContainer}>
                                            {i18n.t('common.amount')}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.tableCellHeader} width={'20%'}>
                                        <div className={classes.tableCellHeaderContainer}>
                                            {i18n.t('common.priceCHF')}
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.tableCellHeader} width={'18%'}>
                                        <div className={classes.tableCellHeaderContainer}>
                                            {i18n.t('common.allergies')}
                                        </div>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ingredients
                                    .filter(filterIngredients)
                                    .sort((ingA, ingB) => {
                                        if (sortIngredients) {
                                            const nameA = ingA.name.toLowerCase(),
                                                nameB = ingB.name.toLowerCase();
                                            if (nameA < nameB)
                                                return sortIngredients === 'desc' ? -1 : 1;
                                            if (nameA > nameB)
                                                return sortIngredients === 'desc' ? 1 : -1;
                                        }
                                        return 0;
                                    })
                                    .map((ingredient, i) => (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <span>{ingredient.name}</span>
                                            </TableCell>
                                            <TableCell align={'left'}>
                                                <span>
                                                    {getCategoryNameOfIngredient(ingredient)}
                                                </span>
                                            </TableCell>
                                            <TableCell align={'left'}>
                                                <span>{ingredient.amount}</span>
                                            </TableCell>
                                            <TableCell align={'left'}>
                                                <span>{ingredient.price}</span>
                                            </TableCell>
                                            <TableCell align={'left'}>
                                                {getAllergenIcons(ingredient)}
                                            </TableCell>
                                            <TableCell align={'right'}>
                                                <BasicThreeDotsMenu
                                                    items={[
                                                        <div
                                                            className={
                                                                classes.threeDotsMenuItemContainer
                                                            }
                                                            onClick={(e) => {
                                                                setSelectedIngredient(ingredient);
                                                                setShowIngredientModal(true);
                                                            }}
                                                        >
                                                            {i18n.t('common.edit')}
                                                        </div>,
                                                        <div
                                                            className={
                                                                classes.threeDotsMenuItemContainer
                                                            }
                                                            onClick={(e) => {
                                                                setSelectedIngredient(ingredient);
                                                                setShowRemoveIngredientModal(true);
                                                            }}
                                                        >
                                                            {i18n.t('button.delete')}
                                                        </div>,
                                                    ]}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={pagingOptions}
                        component="div"
                        count={ingredientsCount}
                        rowsPerPage={ingredientsRowsPerPage}
                        labelRowsPerPage={i18n.t('common.rowsPerPage')}
                        page={ingredientsPage}
                        onPageChange={handleIngredientsChangePage}
                        onRowsPerPageChange={handleIngredientsChangeRowsPerPage}
                    />
                </Paper>
            )}

            {/* Categories */}
            {view === 2 && (
                <Paper>
                    <TableContainer id="container">
                        <Table stickyHeader className={classes.root} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={'20%'}>
                                        <SearchInput
                                            value={categoriesNameSearchText}
                                            name={i18n.t('common.category')}
                                            onChange={(value: string) =>
                                                setCategoriesNameSearchText(value)
                                            }
                                            onEnter={() => ''}
                                            onReset={() => setCategoriesNameSearchText('')}
                                        />
                                    </TableCell>
                                    <TableCell width={'80%'}>
                                        <SearchInput
                                            readOnly
                                            value={''}
                                            name={i18n.t('common.items')}
                                            onChange={(value: string) => ''}
                                            onEnter={() => ''}
                                            onReset={() => ''}
                                        />
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {categories.filter(filterCategory).map((category, i) => (
                                    <TableRow
                                        onClick={() => {
                                            setSelectedCategory(category);
                                            setShowCategoryIngredientsModal(true);
                                        }}
                                        style={{ opacity: category.isDisabled ? 0.3 : 1 }}
                                        key={i}
                                    >
                                        <TableCell>
                                            <span>{category.name}</span>
                                        </TableCell>
                                        <TableCell>
                                            <span>
                                                {category.ingredients.map((ing) => ing.name + ' ')}
                                            </span>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <BasicThreeDotsMenu
                                                items={getCategoryThreeDotsMenu(category)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={pagingOptions}
                        component="div"
                        count={categoriesCount}
                        rowsPerPage={categoriesRowsPerPage}
                        labelRowsPerPage={i18n.t('common.rowsPerPage')}
                        page={categoriesPage}
                        onPageChange={handleCategoriesChangePage}
                        onRowsPerPageChange={handleCategoriesChangeRowsPerPage}
                    />
                </Paper>
            )}
        </div>
    );
}
