import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core';

import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        sectionManagementRoot: {
            margin: '0 !important',
            filter: 'none',
            '&:before': {
                backgroundColor: theme.palette.common.white,
                top: '-2px',
                height: 2,
            },
            '& .MuiListItem-gutters': {
                padding: '0',
            },
            '& .MuiButtonBase-root.MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded':
                {
                    backgroundColor: theme.palette.grey[100],
                    height: '37px !important',
                    minHeight: 0,
                },
            '& .MuiAccordionSummary-content': {
                textAlign: 'center',
            },
            '& .MuiAccordionSummary-content>p': {
                width: '100%',
            },
            '& .MuiAccordion-root.Mui-expanded': {
                margin: 0,
            },
            '& .MuiAccordionDetails-root': {
                display: 'inline-flex',
                flexWrap: 'wrap',
                margin: 0,
                paddingRight: 0,
                paddingLeft: 0,
            },
        },
        toolbarTitle: {
            flex: '1 1 100%',
            display: 'flex',
            justifyContent: 'center',
        },
        sectionLine: {
            background: theme.palette.secondary.light,
        },
        sectionPhoto: {
            height: 24,
            width: 24,
            borderRadius: 50,
            objectFit: 'cover',
            position: 'absolute',
            left: -30,
            top: -3,
        },
        menuLabel: {
            display: 'flex',
            //position: 'absolute',
            right: '30px',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: theme.palette.common.black,
            marginRight: '10px',
            minWidth: '227px',
            height: '23px',
            background: theme.palette.common.white,
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: '5px',
            padding: '0 10px',
        },
        menuPrice: {
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.palette.primary.main,
        },
        chf: {
            color: theme.palette.primary.main,
            fontWeight: 400,
            marginRight: '5px',
            fontSize: '16px',
        },
        currencyText: {
            color: theme.palette.primary.main,
            fontSize: '20px',
            marginRight: '6px',
        },
        rightSideContainer: {
            ...commonStyles.flexRowCenter,
            position: 'absolute',
            //width: 345,
            right: 32,
            top: 3,
            height: 32,
            zIndex: 10,
        },
        itemAmountComponent: {
            ...commonStyles.flexRowSpaceBetween,
            border: `1px solid ${theme.palette.primary.main}`,
            width: 55,
            height: 31,
            borderRadius: 20,
            backgroundColor: theme.palette.common.white,
            zIndex: 2,
            padding: '0 16px',
        },
        itemAmountAction: {
            color: theme.palette.secondary.main,
            fontWeight: 900,
            fontSize: 17,
            cursor: 'pointer',
            lineHeight: '38px',
        },
        itemAmountCount: {
            color: theme.palette.common.black,
            fontWeight: 900,
            fontSize: 15,
            cursor: 'pointer',
            lineHeight: '38px',
        },
        root: {
            '&:focus': {
                backgroundColor: theme.palette.primary.main,
                '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                    color: theme.palette.common.white,
                },
            },
            '& img': {
                width: 'auto',
                maxHeight: 171,
                height: 'auto',
            },
            '& .MuiFormHelperText-root.Mui-error': {
                position: 'absolute',
                bottom: -17,
            },
        },
        formEdit: {
            flexDirection: 'column',
            width: '48%',
            display: 'flex',
            //justifyContent: 'space-between',
            /* '&:last-child': {
                 display: 'flex',
                alignItems: 'end', 
                justifyContent: 'space-between',
            }, */
        },
        formEditExtended: {
            width: 339,
            '&:last-child': {
                position: 'relative',
            },
            '&:nth-child(2)': {
                marginRight: 50,
                marginLeft: 25,
            },
        },
        formWrapper: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
        },
        bottomPanel: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '20px 0 30px 0',
        },
        bottomPanelExtended: {
            width: 700,
            maxWidth: '64%',
            position: 'relative',
            flexWrap: 'wrap',
            '&::after': {
                content: '""',
                height: '300vh',
                width: 1,
                borderLeft: `1px solid ${theme.palette.secondary.main}`,
                bottom: -38,
                right: -29,
                position: 'absolute',
            },
        },
        dialogExtended: {
            '& .MuiDialog-paperWidthMd': {
                width: 1138,
                maxWidth: 1138,
            },
            '& .MuiDialogContent-root': {
                overflowY: 'visible !important',
            },
            '& .MuiDialogContent-root>form>div:first-child': {
                justifyContent: 'flex-start',
            },
        },
        dialog: {
            '& .MuiDialogContent-root': {
                overflowY: 'auto',
            },
            '& form': {
                textAlign: 'end',
            },

            '& .MuiTextField-root': {
                marginBottom: 28,
            },
            '& .MuiDialogTitle-root > h2': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            '& .MuiDialog-paperWidthSm': {
                maxWidth: '750px',
            },
            /* '& .MuiFormControl-root': {
                width: '337px',
            }, */
        },
        dialogTitle: {
            paddingRight: 0,
            width: 702,
            '& h5': {
                ...commonStyles.flexRowEnd,
                margin: 0,
                cursor: 'pointer',
                color: theme.palette.primary.dark,
                fontWeight: 600,
                fontSize: 14,
                letterSpacing: 1.25,
                textTransform: 'uppercase',
            },
            '& img': {
                marginRight: 10,
            },
        },
        dialogTitleExtended: {
            maxWidth: '62%',
        },
        additionalHeader: {
            position: 'absolute',
            top: -53,
            left: 0,
            margin: 0,
            fontWeight: 500,
            fontSize: 20,
            cursor: 'pointer',
            '& img': {
                marginRight: 10,
            },
        },
        actionsArea: {
            margin: '10px 0 15px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&>span': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
            },
            '& button': {
                margin: 0,
            },
            '&>div': {
                width: 180,
                display: 'flex',
                justifyContent: 'space-between',
            },
        },
        bottomContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        bottomIcon: {
            margin: '0 20px 0 -10px',
        },
        photoContainer: {
            minHeight: 158,
            //width: '48%',
            //marginRight: '5%',
            margin: '0 auto',
            width: '100%',
        },
        changePhotoButton: {
            position: 'absolute',
            top: '25%',
            left: '4.5%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.primary.main,
            width: '155px',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        removePhotoButton: {
            position: 'absolute',
            top: '25%',
            left: '26%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.error.light,
            width: '155px',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        manageIngredientButton: {
            letterSpacing: 1.25,
            width: '100%',
            height: '56px',
            borderRadius: 0,
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        addMoreSizesButton: {
            letterSpacing: 1.25,
            width: '100%',
            height: '36px',
            borderRadius: 0,
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        addMoreSizesButtonExtended: {
            maxWidth: 703,
        },
        container: {
            margin: '10px',
        },
        chooseExistingContainer: {
            minHeight: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        chooseExistingTitle: {
            fontWeight: 500,
        },
        buttonContainer: {
            display: 'flex',
            alignSelf: 'flex-end',
            width: '45%',
            flexDirection: 'row',
            marginTop: '10px',
            justifyContent: 'space-between',
        },
        daysContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        dayButton: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 53,
            height: 35,
            fontWeight: 500,
            color: theme.palette.secondary.main,
            textTransform: 'uppercase',
            fontSize: 14,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 3,
            cursor: 'pointer',
            userSelect: 'none',
        },
        dayButtonSelected: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
        serviceTaxRoot: {
            marginBottom: 28,
            height: 56,
            maxHeight: 56,
            '& .MuiSelect-root': {
                paddingTop: 15,
                paddingBottom: 14,
            },
            '& .MuiList-root': {
                width: '100%',
            },
        },
        textContainer: {
            ...commonStyles.flexRowSpaceBetween,
        },
        slectedRate: {
            color: theme.palette.primary.dark,
            fontWeight: 700,
        },
        selectedNone: {
            color: theme.palette.secondary.main,
        },
        menuList: {
            width: '100%',
        },
        selectTaxError: {
            color: theme.palette.error.main,
            position: 'absolute',
            transform: 'translate(12px, 10px) scale(0.75)',
            top: -10,
            left: -30,
            fontSize: '1rem',
        },
        hasError: {
            position: 'relative',
            color: `${theme.palette.error.main} !important`,
            '& h5': {
                color: `${theme.palette.error.main} !important`,
            },
            '& span': {
                position: 'absolute',
                right: 0,
                fontSize: 10,
            },
        },
        'MuiCardHeader-root': {
            paddingBottom: '0px',
        },
        dishPreviewItemRoot: {
            margin: '15px 5px 33px 5px',
            width: 345,
            height: '185px !important',
            '&:hover': {
                cursor: 'pointer',
            },
            boxShadow: `0px 4px 8px 0px ${alpha(theme.palette.common.black, 0.11)}`,
            //border: '1px solid transparent',
        },
        selected: {
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        spicesContainer: {
            height: 20,
            '& button': {
                marginLeft: '10px',
            },
            '& button:first-child': {
                marginLeft: 0,
            },
        },
        coverImg: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        cardPrice: {
            fontSize: '14px',
            color: theme.palette.primary.main,
            lineHeight: '16.5px',
        },
        cardTitleContainer: {
            height: 20,
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        cardTitle: {
            fontWeight: 'bold',
            fontSize: '18px',
            marginRight: 10,
        },
        cardDesc: {
            fontSize: '14px',
            color: theme.palette.common.black,
        },
        cardContent: {
            height: '22%',
            paddingBottom: 11,
        },
        cardBottom: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: 0,
        },
        labelPrice: {
            fontSize: '20px',
            fontWeight: 900,
            lineHeight: '23.44px',
        },
        itemAmountContainer: {
            ...commonStyles.flexRowCenter,
            position: 'absolute',
            width: 345,
            right: 0,
            bottom: 16,
        },
        forModal: {
            margin: '15px 5px 33px 10px',
        },
        smallText: {
            fontSize: 14,
        },
        sideMargins: {
            margin: '0 5px',
        },
        barcodeIcon: {
            height: '36px !important',
            width: 'auto',
            marginRight: 10,
            marginLeft: 20,
        },
        smallFont: {
            fontSize: 13,
            '& .MuiButton-label': {
                fontSize: 13,
            },
        },
    })
);
