import React, { useEffect, useState } from 'react';

import i18n from 'i18n-js';
import { Form, Formik, getIn } from 'formik';
import clsx from 'clsx';
import { InputAdornment, TextField, TextField as MuiTextField } from '@material-ui/core';
import { Typography, Dialog, DialogTitle, DialogContent, Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import * as yup from 'yup';

import { images } from 'assets';
import { Input } from 'components/shared';
import { CategoryDetailsData, CategoryDishData } from 'store/categoryStore/reducer';
import { trimLeftAndRight } from 'helpers/helperFunctions';
import styles from '../styles';
import { PositionsList } from 'constants/lists';

interface CreateOrEditCategoryProps {
    isVisible: boolean;
    onClose: () => void;
    onCreate: (categoryName: string, dishes: string[], position: string) => void;
    onEdit: (categoryName: string, dishes: string[], position: string, categoryId: string) => void;
    categoryDetails: CategoryDetailsData;
    dishes: CategoryDishData[];
    categoryName: string;
    categoryId: string;
    position: string;
    isEdit: boolean;
    categories: string[];
}

export default function CreateOrEditCategoryModal(props: CreateOrEditCategoryProps) {
    const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
    const classes = styles();

    useEffect(() => {
        setCheckedOptions(getCheckedDishes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dishes]);

    function preventPropagationAndCheck(e: any, id: string) {
        e.stopPropagation();
        e.preventDefault();
        setCheckedOne(id);
    }

    function getCheckedDishes(): string[] {
        if (props.dishes.length > 0) {
            const dishIds: string[] = [];
            props.dishes.map((dish) => dishIds.push(dish.id));
            return dishIds;
        }
        return [];
    }

    function setCheckedOne(id: string) {
        let newCheckedOptions = [...checkedOptions];
        if (checkedOptions.includes(id)) {
            newCheckedOptions = newCheckedOptions.filter((currentOption) => currentOption !== id);
        } else {
            newCheckedOptions.push(id);
        }
        setCheckedOptions(newCheckedOptions);
    }

    const validationSchema = yup.object().shape({
        categoryName: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Work Area Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !props.categories.includes(value?.toUpperCase())
            ),
        position: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Position Name',
            })
        ),
    });

    function getAvailableDishes() {
        const availableDishes = props.categoryDetails.dishes.filter((dish) => !dish.categoryId);
        return [...availableDishes, ...props.dishes];
    }

    function onCloseClicked() {
        props.onClose();
    }

    return (
        <Dialog open={props.isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6" align="left" className={classes.fontWeight500}>
                    {i18n.t(
                        `${props.isEdit ? 'restaurant.editWorkArea' : 'restaurant.createWorkArea'}`
                    )}
                </Typography>
            </DialogTitle>
            <DialogContent style={{ overflowY: 'auto' }}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        categoryName: props.categoryName,
                        dishes: props.dishes,
                        position: props.position,
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        if (values.categoryName !== '') {
                            setSubmitting(false);
                            if (props.isEdit) {
                                props.onEdit(
                                    trimLeftAndRight(values.categoryName),
                                    checkedOptions,
                                    values.position,
                                    props.categoryId
                                );
                            } else {
                                props.onCreate(
                                    trimLeftAndRight(values.categoryName),
                                    checkedOptions,
                                    values.position
                                );
                            }
                            props.onClose();
                        }
                    }}
                >
                    {({ errors, submitForm, values, setFieldValue, touched }) => (
                        <Form className={classes.smallModalContainer}>
                            <Input
                                error={errors.categoryName}
                                variant="filled"
                                name={`categoryName`}
                                type="text"
                                label={i18n.t('restaurant.workArea')}
                                placeholder={i18n.t('restaurant.workArea')}
                            />
                            <>
                                <Autocomplete
                                    id="dishes"
                                    options={getAvailableDishes()}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderOption={(option) => (
                                        <div
                                            onClick={(e) =>
                                                preventPropagationAndCheck(e, option.id)
                                            }
                                            className={clsx(classes.width100, classes.flexRowStart)}
                                        >
                                            <Checkbox
                                                checked={checkedOptions.includes(option.id)}
                                            />
                                            <div
                                                className={classes.coffeeCupIcon}
                                                style={{
                                                    backgroundImage: `url(${images.icons.coffee})`,
                                                }}
                                            />
                                            {option.name}
                                        </div>
                                    )}
                                    renderInput={(params) => (
                                        <>
                                            <MuiTextField
                                                {...params}
                                                name="dishes"
                                                className={classes.dishesSelect}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <div
                                                                className={classes.coffeeCupIcon}
                                                                style={{
                                                                    backgroundImage: `url(${images.icons.coffee})`,
                                                                }}
                                                            />
                                                            <span>
                                                                {`${checkedOptions.length} ${i18n.t(
                                                                    'common.items'
                                                                )}`}
                                                            </span>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                label={i18n.t('common.chooseDishes')}
                                                variant="filled"
                                            />
                                        </>
                                    )}
                                />
                                <Autocomplete
                                    options={PositionsList.map((item) => item.name)}
                                    getOptionLabel={(option) => option}
                                    value={values.position}
                                    getOptionSelected={(option, value) => option === value}
                                    onChange={(e: object, value: any | null) => {
                                        setFieldValue('position', value);
                                    }}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                name="position"
                                                label={i18n.t('form.responsiblePosition')}
                                                variant="filled"
                                                error={
                                                    values.position === '' &&
                                                    getIn(touched, 'position')
                                                }
                                            />
                                        </>
                                    )}
                                />
                            </>
                            <div className={classes.flexRowEnd}>
                                <div className={classes.smallModalButtonContainer}>
                                    <Button
                                        style={{ marginRight: '15px' }}
                                        type="button"
                                        color="primary"
                                        onClick={onCloseClicked}
                                    >
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {i18n.t(
                                            `${props.isEdit ? 'button.save' : 'common.create'}`
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
