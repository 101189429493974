import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import * as yup from 'yup';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import i18n from 'i18n-js';

import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import BorderColorIcon from '@material-ui/icons/BorderColor';

import { SectionData } from 'store/menuDetailsStore/reducer';
import menuDetailsStore from 'store/menuDetailsStore';
import { DaysWithHours } from 'components/menu/MenuManagementTable/components/AddDailyMenuSectionModal';
import { getDayList } from 'helpers/datetimeHelper';
import { Input } from 'components/shared';
import ImageContainer, {
    ImageContainerRefProps,
} from 'components/shared/components/ImageContainer';
import { useStyles } from '../styles';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface EditDailyMenuSectionProps {
    section: SectionData;
    onClose: any;
    menus: string[];
}

export default function EditDailyMenuSection(props: EditDailyMenuSectionProps) {
    const dispatch = useDispatch();
    const [showEditPopup, setShowEditPopup] = useState(false);

    function handleClose() {
        setShowEditPopup(false);
        setSelectedDays([]);
        props.onClose();
    }

    function submitForm(name: string, price: number, photo: string, availability: DaysWithHours) {
        dispatch(
            menuDetailsStore.actionCreators.editDailyMenuSection(props.section?.id, {
                name,
                price,
                availability,
                photo,
            })
        );
        setShowEditPopup(false);
        setSelectedDays([]);
        props.onClose();
    }

    const initialValues = {
        name: props.section?.name,
        price: props.section?.price ?? 0,
        photo: props.section?.photo || '',
    };

    const classes = useStyles();
    const daysWithHours = useRef({ ...props.section?.availability! });
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const imageContainerRef = useRef<ImageContainerRefProps>(null);
    function handleDaySelection(day: string) {
        if (selectedDays.includes(day)) {
            const updatedDays = selectedDays.filter((x) => x !== day);
            setSelectedDays(updatedDays);
        } else {
            setSelectedDays([...selectedDays, day]);
        }
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !props.menus.includes(value?.toUpperCase())
            ),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            )
            .min(0.1),
    });

    return (
        <>
            <StyledMenuItem
                color="primary"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowEditPopup(true);
                }}
            >
                <BorderColorIcon fontSize="small" color="disabled" style={{ marginRight: 23 }} />
                <ListItemText primary={i18n.t('common.edit')} />
            </StyledMenuItem>
            <Dialog
                open={showEditPopup}
                aria-labelledby="form-dialog-title"
                onClick={(e) => e.stopPropagation()}
            >
                <DialogTitle id="form-dialog-title">{i18n.t('common.editMenu')}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            imageContainerRef?.current?.uploadImage().then((photo) => {
                                if (photo.length > 0) values.photo = photo;
                                submitForm(values.name, values.price, values.photo, {
                                    ...daysWithHours.current,
                                });
                            });
                        }}
                    >
                        {({ errors, submitForm, values }) => (
                            <Form>
                                <ImageContainer ref={imageContainerRef} photo={values.photo} />
                                <>
                                    <Input
                                        error={errors.name}
                                        variant="filled"
                                        fullWidth={true}
                                        name={`name`}
                                        type="text"
                                        label={i18n.t('form.name')}
                                        placeholder={i18n.t('form.name')}
                                    />
                                    <br />
                                    <Input
                                        error={errors.price}
                                        variant="filled"
                                        fullWidth={true}
                                        name={`price`}
                                        type="text"
                                        label={i18n.t('form.price')}
                                        placeholder={i18n.t('form.price')}
                                    />
                                </>
                                <div className={classes.daysContainer}>
                                    {getDayList(daysWithHours.current).map((day) => (
                                        <div
                                            key={day.name}
                                            className={clsx(classes.dayButton, {
                                                [classes.dayButtonSelected]: selectedDays.includes(
                                                    day.key
                                                ),
                                            })}
                                            onClick={() => handleDaySelection(day.key)}
                                        >
                                            {day.name}
                                        </div>
                                    ))}
                                </div>
                                <br />
                                <DialogActions>
                                    <Button type="button" color="primary" onClick={handleClose}>
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {i18n.t('common.save')}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}
