import React, { useState, useEffect } from 'react';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Input,
    makeStyles,
    ListItemIcon,
} from '@material-ui/core';
import clsx from 'clsx';

import agent from 'api/agent';
import { DishLabel } from 'components/restaurants/restaurantsPage/components/LabelsModal';
import { useAppSelector } from 'index';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 80,
        maxWidth: 350,
        marginRight: 10,
        '& .MuiInputLabel-formControl': {
            transform: 'none',
        },
        '& label + .MuiInput-formControl': {
            marginTop: 0,
            transform: 'none',
        },
        '& .MuiFormLabel-root.MuiInputLabel-root': { top: 8, color: theme.palette.common.black },
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            transition: 'none',
        },
        '& .MuiSelect-root.MuiSelect-select': {
            textAlign: 'start',
        },
    },
    smallFont: {
        fontSize: 14,
        '& .MuiTypography-root': {
            fontSize: 14,
        },
    },
}));

interface LabelSelectProps {
    selectedLabels: string[];
    onChange: (selectedLabels: string[]) => void;
}

export default function LabelSelect(props: LabelSelectProps) {
    const classes = useStyles();
    const lang = useAppSelector((state) => state.global.language);
    const [labels, setLabels] = useState<DishLabel[]>([]);

    async function getLabels() {
        const data = await agent.Products.GetLabels();
        setLabels(data);
    }

    function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
        props.onChange(event.target.value as string[]);
    }

    function renderSelected(selected: string[]) {
        let result = labels.find((label) => label.id === selected[0]);
        if (selected.length > 1) {
            return `${result?.name || ''} ...`;
        } else {
            return (
                <div>
                    <span
                        className={clsx({
                            [classes.smallFont]: lang === 'de',
                        })}
                    >
                        {result?.name || ''}
                    </span>
                    <img
                        src={result?.icon || ''}
                        alt="selected-sicon"
                        style={{ height: 14, marginLeft: 7 }}
                    />
                </div>
            );
        }
    }

    useEffect(() => {
        getLabels();
    }, []);

    return (
        <FormControl className={classes.root}>
            <InputLabel
                id="demo-mutiple-checkbox-label"
                disableAnimation
                className={clsx({
                    [classes.smallFont]: lang === 'de',
                })}
            >
                {props.selectedLabels.length === 0 ? 'Label' : ''}
            </InputLabel>
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={props.selectedLabels}
                onChange={handleChange}
                input={
                    <Input
                        disableUnderline
                        placeholder="Label"
                        className={clsx({
                            [classes.smallFont]: lang === 'de',
                        })}
                    />
                }
                renderValue={(selected) => renderSelected(selected as string[])}
                //MenuProps={MenuProps}
            >
                {labels.map((label) => (
                    <MenuItem key={label.id} value={label.id}>
                        <Checkbox checked={props.selectedLabels?.indexOf(label.id) > -1} />
                        <ListItemText
                            primary={label.name}
                            className={clsx({
                                [classes.smallFont]: lang === 'de',
                            })}
                        />
                        <ListItemIcon>
                            <img src={label.icon} alt={label.name} height={14} />
                        </ListItemIcon>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
