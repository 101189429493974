import agent from 'api/agent';
import { IngredientModel, UpdateCategoryModel, UpdateIngredientModel } from 'api/models';

import { AppThunkAction } from '../index';
import globalStore from '../globalStore';
import { IngredientsAction } from './actions';

export const actionCreators = {
    getIngredients:
        (
            lang: 'en' | 'de' = 'en',
            page = 0,
            limit = 13,
            getAll = false
        ): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_INGREDIENTS_START',
            });
            agent.Products.GetIngredients(lang, page, limit, getAll).then((res) => {
                dispatch({
                    type: 'GET_INGREDIENTS_SUCCESS',
                    data: res.list,
                    count: res.count,
                });
            });
        },
    updateIngredient:
        (model: UpdateIngredientModel): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_INGREDIENT_START',
            });
            agent.Products.UpdateIngredient(model).then(() => {
                // actionCreators.getIngredients()(dispatch);
                dispatch({
                    type: 'UPDATE_INGREDIENT_SUCCESS',
                    data: model,
                });
            });
        },
    createIngredient:
        (model: IngredientModel): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            dispatch({
                type: 'CREATE_INGREDIENT_START',
            });
            agent.Products.CreateIngredient(model).then((res) => {
                dispatch({
                    type: 'CREATE_INGREDIENT_SUCCESS',
                    data: res,
                });
            });
        },
    removeIngredient:
        (model: IngredientModel): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            globalStore.actionCreators.showSpiner()(dispatch);
            dispatch({
                type: 'REMOVE_INGREDIENT_START',
            });
            agent.Products.RemoveIngredient(model)
                .then(() => {
                    dispatch({
                        type: 'REMOVE_INGREDIENT_SUCCESS',
                        data: model,
                    });
                })
                .catch((e) => globalStore.actionCreators.showToaster('error', e)(dispatch))
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getCategories:
        (lang: 'en' | 'de' = 'en', page = 0, limit = 13): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Products.GetCategories(lang, page, limit).then((res) => {
                dispatch({
                    type: 'GET_INGREDIENT_CATEGORIES_SUCCESS',
                    data: res.list,
                    count: res.count,
                });
            });
        },
    createCategory:
        (name: string): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Products.CreateCategory(name).then((res) => {
                dispatch({
                    type: 'CREATE_INGREDIENT_CATEGORY_SUCCESS',
                    data: res,
                });
            });
        },
    updateCategory:
        (model: UpdateCategoryModel): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Products.UpdateCategory(model).then((res) => {
                dispatch({
                    type: 'UPDATE_INGREDIENT_CATEGORY_SUCCESS',
                    data: res,
                });
            });
        },
    deleteCategory:
        (id: string): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Products.DeleteCategory(id).then(() => {
                dispatch({
                    type: 'DELETE_INGREDIENT_CATEGORY_SUCCESS',
                    id,
                });
            });
        },
    disableEnableCategory:
        (id: string): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            agent.Products.DisableEnable(id).then(() => {
                dispatch({
                    type: 'DISABLE_INGREDIENT_CATEGORY_SUCCESS',
                    id,
                });
            });
        },
    getAllergies:
        (lang: string): AppThunkAction<IngredientsAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_ALLERGIES_START',
            });
            agent.Products.GetAllergiens(lang)
                .then((res) => {
                    dispatch({
                        type: 'GET_ALLERGIES_SUCCESS',
                        allergies: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_ALLERGIES_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                });
        },
};
