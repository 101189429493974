import React from 'react';

import { FormControl, Select, MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core';
import clsx from 'clsx';

import { MapObjectData } from 'constants/maps';
import commonStyles from 'config/commonStyles';
import { useAppSelector } from 'index';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        root: {
            //maxWidth: '30%',
            marginRight: 15,
        },
        textContainer: {
            ...commonStyles.flexRowSpaceBetween,
        },
        sideMargins: {
            margin: '0 5px',
        },
        menuList: {
            width: '100%',
        },
        smallFont: {
            fontSize: 14,
        },
    })
);

interface DishDietarySelectProps<T> {
    name: string;
    value: T;
    map: Map<T, MapObjectData>;
    onChange: (value: T) => void;
    disabled?: boolean;
}

export default function DishDietarySelect<T>(props: DishDietarySelectProps<T>) {
    const classes = useStyles();
    const lang = useAppSelector((state) => state.global.language);
    const selectOptions = Array.from(props.map.keys());
    function renderOption(key: T, fullView?: boolean, placeholder?: boolean) {
        const data = props.map.get(key)!;
        const name = placeholder ? (data.icon ? data.name : props.name) : data.name;
        if (fullView) {
            return (
                <div className={classes.textContainer}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {data.icon ? (
                            <>
                                <img src={data.icon} alt={name} />
                                <span
                                    className={clsx({
                                        [classes.smallFont]: lang === 'de',
                                    })}
                                    style={{ marginLeft: 10 }}
                                >
                                    {name}
                                </span>
                            </>
                        ) : (
                            <span
                                className={clsx({
                                    [classes.smallFont]: lang === 'de',
                                })}
                            >
                                {name}
                            </span>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    {data.icon ? (
                        <span
                            className={clsx({
                                [classes.smallFont]: lang === 'de',
                            })}
                        >
                            {name}{' '}
                            <img src={data.icon} alt={name} className={classes.sideMargins} />
                        </span>
                    ) : (
                        <span
                            className={clsx({
                                [classes.smallFont]: lang === 'de',
                            })}
                        >
                            {name}
                        </span>
                    )}
                </>
            );
        }
    }
    return (
        <>
            <FormControl className={classes.root}>
                {/* <InputLabel>{props.name}</InputLabel> */}
                <Select
                    disableUnderline
                    placeholder={props.name}
                    disabled={props.disabled}
                    MenuProps={{
                        classes: {
                            list: classes.menuList,
                        },
                    }}
                    label={props.name}
                    onChange={(e) => {
                        props.onChange(e.target.value as T);
                    }}
                    style={{ textAlign: 'start' }}
                    value={props.value}
                    renderValue={() => renderOption(props.value, false, true)}
                >
                    {selectOptions.map((option) => (
                        <MenuItem
                            value={option as unknown as string}
                            key={option as unknown as string}
                        >
                            {renderOption(option, true)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
