import React, { useRef, useState } from 'react';

import clsx from 'clsx';
import i18n from 'i18n-js';
import { Form, Formik } from 'formik';
import Dialog from '@material-ui/core/Dialog';
import * as yup from 'yup';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { DialogActions } from '@material-ui/core';

import { Input } from 'components/shared';
import { HourData } from 'components/restaurantInformation/components/AddWorkingHoursModal';
import { getDayList } from 'helpers/datetimeHelper';
import ImageContainer, {
    ImageContainerRefProps,
} from 'components/shared/components/ImageContainer';

import { useStyles } from '../styles';

interface AddDailyMenuSectionProps {
    isVisible: boolean;
    onClose: () => void;
    onAdd: (model: any) => void;
    menus: string[];
}

export interface DaysWithHours {
    sunday: HourData;
    monday: HourData;
    tuesday: HourData;
    wednesday: HourData;
    thursday: HourData;
    friday: HourData;
    saturday: HourData;
}

const defaultHours = { from: '00.00', to: '00.00' };

export default function AddDailyMenuSectionModal(props: AddDailyMenuSectionProps) {
    const classes = useStyles();

    const [selectedDays, setSelectedDays] = useState<string[]>([]);

    const daysWithHours = useRef({
        sunday: { ...defaultHours },
        monday: { ...defaultHours },
        tuesday: { ...defaultHours },
        wednesday: { ...defaultHours },
        thursday: { ...defaultHours },
        friday: { from: '10.00', to: '12.00' },
        saturday: { ...defaultHours },
    });
    const imageContainerRef = useRef<ImageContainerRefProps>(null);

    function handleDaySelection(day: string) {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter((x) => x !== day));
        } else {
            setSelectedDays([...selectedDays, day]);
        }
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !props.menus.includes(value?.toUpperCase())
            ),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            )
            .min(0.1),
    });

    return (
        <Dialog open={props.isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{i18n.t('restaurant.addNewMenu')}</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        name: '',
                        price: 0.0,
                        photo: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        imageContainerRef?.current?.uploadImage().then((photo) => {
                            if (photo.length > 0) values.photo = photo;
                            props.onAdd({
                                name: values.name,
                                price: values.price,
                                photo: values.photo,
                                availability: { ...daysWithHours.current },
                            });
                        });
                        /* </DialogContent>/const result = await agent.Menus.AddDailyMenuSection(model); */
                    }}
                >
                    {({ errors, submitForm, values }) => (
                        <Form>
                            <ImageContainer ref={imageContainerRef} photo={values.photo} />
                            <>
                                <Input
                                    error={errors.name}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`name`}
                                    type="text"
                                    label={i18n.t('form.menuName')}
                                    placeholder={i18n.t('form.name')}
                                />
                                <br />
                                <Input
                                    error={errors.price}
                                    variant="filled"
                                    fullWidth={true}
                                    name={`price`}
                                    type="text"
                                    label={i18n.t('form.price')}
                                    placeholder={i18n.t('form.price')}
                                />
                            </>
                            <div className={classes.daysContainer}>
                                {getDayList(daysWithHours.current).map((day) => (
                                    <div
                                        key={day.name}
                                        className={clsx(classes.dayButton, {
                                            [classes.dayButtonSelected]: selectedDays.includes(
                                                day.key
                                            ),
                                        })}
                                        onClick={() => handleDaySelection(day.key)}
                                    >
                                        {day.name}
                                    </div>
                                ))}
                            </div>
                            <br />
                            <DialogActions>
                                <Button type="button" color="primary" onClick={props.onClose}>
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button onClick={submitForm} variant="contained" color="primary">
                                    {i18n.t('common.add')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}
