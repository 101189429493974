import { Action, Reducer } from 'redux';

import { Positions } from 'constants/enums';
import { CategoryAction } from './actions';

export interface CreateCategoryModel {
    Name: string;
    Dishes: string[];
    position: string;
}

export interface CategoryDishData {
    id: string;
    name: string;
    categoryId: string | null;
}

export interface CategoryDetails {
    name: string;
    id: string;
    isDisabled: boolean;
    position: Positions;
}

export interface CategoryDetailsData {
    categories: CategoryDetails[];
    dishes: CategoryDishData[];
}
export interface CategoryState {
    categoryDetails: CategoryDetailsData | null;
}
const initialState: CategoryState = {
    categoryDetails: null,
};

export const reducer: Reducer<CategoryState> = (
    state: CategoryState | undefined = initialState,
    incomingAction: Action
): CategoryState => {
    const action = incomingAction as CategoryAction;

    switch (action.type) {
        case 'GET_CATEGORY_DETAILS_SUCCESS': {
            return {
                ...state,
                categoryDetails: action.data,
            };
        }
        case 'CREATE_CATEGORY_SUCCESS': {
            const newCategoryDetails: any = { ...state?.categoryDetails };
            newCategoryDetails.categories.push({
                id: action.id,
                name: action.name,
            });
            return {
                ...state,
                categoryDetails: newCategoryDetails,
            };
        }
        case 'DISABLE_OR_ENABLE_CATEGORIES_SUCCESS': {
            const newCategoryDetails: any = { ...state?.categoryDetails };
            newCategoryDetails.categories = newCategoryDetails.categories
                ? newCategoryDetails.categories.map((category: { id: string }) =>
                      action.result.categoriesIds.includes(category.id)
                          ? { ...category, isDisabled: action.result.isDisabled }
                          : category
                  )
                : newCategoryDetails.categories;
            return {
                ...state,
                categoryDetails: newCategoryDetails as CategoryDetailsData,
            };
        }
        case 'DELETE_CATEGORY_SUCCESS': {
            const updatedCategories = state.categoryDetails?.categories.filter(
                (i) => i.id !== action.payload.categoryId
            );
            const updatedCategoryDetails = {
                ...state.categoryDetails,
                categories: updatedCategories as CategoryDetails[],
            };
            return {
                ...state,
                categoryDetails: updatedCategoryDetails as CategoryDetailsData,
            };
        }
        case 'ADD_DISH_TO_CATEGORIES_DISHES_SUCCESS': {
            let updatedCategoryDetails: CategoryDetailsData = state.categoryDetails
                ? { ...state.categoryDetails }
                : { categories: [], dishes: [] };
            updatedCategoryDetails.dishes.push(action.payload);
            return {
                ...state,
                categoryDetails: updatedCategoryDetails,
            };
        }
        case 'REMOVE_DISH_FROM_CATEGORIES_DISHES_SUCCESS': {
            if (state.categoryDetails === null) {
                return {
                    ...state,
                };
            } else {
                let updatedCategoryDetails: CategoryDetailsData = state.categoryDetails;
                updatedCategoryDetails.dishes = updatedCategoryDetails.dishes.filter(
                    (dish) => dish.id !== action.payload
                );
                return {
                    ...state,
                    categoryDetails: updatedCategoryDetails,
                };
            }
        }
        default:
            return state;
    }
};
